import { type FormatsEnum } from './formats';

export enum PropertyRef {
  USER = 'USER',
}

export interface Property {
  name: string;
  machineName: string;
  format: FormatsEnum;

  isEnum?: boolean;

  isRequired?: boolean;

  // This optional ref is used to indicate that
  // the property is a reference to another object, like a user.
  ref?: PropertyRef;

  // This optional baseMachineName is used to indicate the original machineName of the current property. (in case we had to do a downcast when 2 properties have the same name but differents formats)
  baseMachineName?: string;
}
