import { type ClassNamesContent } from '@emotion/react';

const pickerViews = ['day', 'month-text', 'quarter-text', 'year-text'] as const;

// This applies to .react-datepicker element.
export const datePickerBasePopover = ({ css, theme }: ClassNamesContent) => css`
  &.react-datepicker {
    display: flex;
    flex-direction: column;
    border: none;

    box-shadow: ${theme.ds.shadows.hard};

    background-color: ${theme.ds.colors.gray[0]};
  }

  .react-datepicker__header {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .react-datepicker__day-names {
    margin: 0 auto 8px;
  }

  .react-datepicker__day-name {
    ${theme.ds.typographies.bodyXsmallMedium};

    margin: 0;
    width: 24px;
    height: 24px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: ${theme.ds.colors.gray[700]};
  }

  .react-datepicker__month,
  .react-datepicker__year {
    margin: 0 auto;
    padding: 0 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .react-datepicker__year-wrapper {
    max-width: unset;
    gap: 6px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week,
  .react-datepicker__year-wrapper,
  .react-datepicker__month-wrapper,
  .react-datepicker__quarter-wrapper {
    width: 214px;
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__month {
    .react-datepicker__day {
      width: 24px;
    }

    .react-datepicker__month-text,
    .react-datepicker__quarter-text {
      width: 40px;
    }
  }

  .react-datepicker__year .react-datepicker__year-text {
    width: 40px;
  }

  ${pickerViews.map(
    (pickerView) => css`
      .react-datepicker__month,
      .react-datepicker__year {
        .react-datepicker__${pickerView} {
          ${theme.ds.typographies.bodySmallMedium};

          border-radius: ${theme.ds.borderRadiuses.squared};

          color: ${theme.ds.colors.gray[800]};
          background-color: ${theme.ds.colors.graySecondary[50]};

          transition: ${theme.ds.transitions.default('color', 'background-color', 'box-shadow')};

          margin: 0;
          height: 24px;

          display: inline-flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          &.react-datepicker__${pickerView}--today {
            ${theme.ds.typographies.bodySmallBold};
          }

          &.react-datepicker__${pickerView}--disabled {
            color: ${theme.ds.colors.gray[300]};
            background-color: ${theme.ds.colors.gray[0]};
            cursor: not-allowed;
          }

          &:not(.react-datepicker__${pickerView}--disabled) {
            &:hover,
            &.react-datepicker__${pickerView}--in-selecting-range {
              &,
              &.react-datepicker__${pickerView}--outside-month {
                background-color: ${theme.ds.colors.primary[50]};
              }
            }

            &.react-datepicker__${pickerView}--selected, &.react-datepicker__${pickerView}--in-range {
              &,
              &.react-datepicker__${pickerView}--outside-month {
                color: ${theme.ds.colors.primary[500]};
                background-color: ${theme.ds.colors.primary[50]};
              }
            }

            &.react-datepicker__${pickerView}--keyboard-selected, &:focus-visible {
              outline: 1px solid ${theme.ds.colors.primary[200]};
              outline-offset: -1px;
              box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
            }

            &.react-datepicker__${pickerView}--outside-month {
              color: ${theme.ds.colors.gray[600]};
              background-color: ${theme.ds.colors.gray[0]};
            }
          }
        }
      }
    `,
  )}

  .react-datepicker__children-container {
    border-top: 1px solid ${theme.ds.colors.gray[100]};
    width: 100%;
    margin: 0;
  }

  .react-datepicker__input-time-container {
    width: auto;
    margin: 0 13px 13px;
  }
`;
