import { type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { type OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';
import { type Period, PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type BadgeAward, type Plan, type RuleType } from '@amalia/payout-definition/plans/types';
import { type TeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type AssignmentType, type MatchScope } from '@amalia/tenants/teams/types';
import { type UserComputed, type UserContract } from '@amalia/tenants/users/types';

import { type CalculationType } from './calculation';
import { type EngineError } from './calculationErrors';
import { type ComputedStatement, type ComputedStatementSummary } from './computedStatement';
import { type TeamOption } from './options';
import { type PaymentCategory } from './payments';
import { type StatementForecast } from './statementForecast';
import { type PlanOption } from './userStatements';

export const StatementFrequency = {
  [PeriodFrequencyEnum.month]: PeriodFrequencyEnum.month,
  [PeriodFrequencyEnum.quarter]: PeriodFrequencyEnum.quarter,
} as const;

export interface Adjustment {
  id: string;
  name: string;
  paymentPeriodId: string | null;
  description?: string | null;
  amount: number;
  rowDefinitionId?: string;
  rowExternalId?: string;
  rowName?: string;
  statement?: Statement;
}

export type CreateAdjustmentRequest = {
  name: Adjustment['name'];
  statementId: Statement['id'];
  description?: Adjustment['description'];
  amount: Adjustment['amount'];
  rowDefinitionId?: Adjustment['rowDefinitionId'];
  rowExternalId?: Adjustment['rowExternalId'];
  paymentPeriodId?: Adjustment['paymentPeriodId'];
};

export type UpdateAdjustmentRequest = {
  name: Adjustment['name'];
  description?: Adjustment['description'];
  amount: Adjustment['amount'];
  rowDefinitionId?: Adjustment['rowDefinitionId'];
  rowExternalId?: Adjustment['rowExternalId'];
  paymentPeriodId?: Adjustment['paymentPeriodId'];
};

export interface StatementTeamAssignment {
  id: string;
  teamId: string;
  teamName: string;
}

export interface StatementParentTeams {
  teamId: string;
  teamName: string;
}

export interface Statement {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  workflowComplete: boolean;
  workflowSteps?: WorkflowStatementState[] | null;
  workflowCompletedDate?: Date | null;
  workflowUpdatedAt?: Date | null;

  badgeAwards?: BadgeAward[];

  results: ComputedStatement;
  resultSummary?: ComputedStatementSummary | null;
  forecast?: Pick<
    StatementForecast,
    'currency' | 'error' | 'lastCalculationDate' | 'planForecast' | 'rate' | 'results' | 'total'
  > & {
    resultSummary?: ComputedStatementSummary;
  };
  forecastId?: string | null;
  lastCalculationDate?: Date;

  userId?: string;
  user?: UserComputed;
  error?: EngineError | null;
  currency: CurrencySymbolsEnum;
  rate: number;
  total: number;

  adjustments?: Adjustment[];
  planId: string;
  plan?: Plan;
  period?: Period;
  company?: Company;

  // Direct team assignments of the user during statement computation.
  directTeamAssignments?: StatementTeamAssignment[] | null;
  // Parent teams of the user during statement computation.
  parentTeamAssignments?: StatementParentTeams[] | null;
}

export type StatementResponse = Pick<
  Statement,
  | 'badgeAwards'
  | 'currency'
  | 'error'
  | 'forecast'
  | 'forecastId'
  | 'id'
  | 'lastCalculationDate'
  | 'rate'
  | 'results'
  | 'resultSummary'
  | 'total'
  | 'updatedAt'
  | 'workflowComplete'
  | 'workflowCompletedDate'
  | 'workflowSteps'
  | 'workflowUpdatedAt'
> & {
  adjustments: Adjustment[];

  user: Pick<UserContract, 'email' | 'firstName' | 'id' | 'lastName' | 'pictureURL'>;

  plan?: Pick<
    Plan,
    | 'forecastId'
    | 'frequency'
    | 'highlightedKpis'
    | 'id'
    | 'isForecasted'
    | 'isHidden'
    | 'isSimulationAddRecordEnabled'
    | 'isSimulationEnabled'
    | 'linkToPlanDocumentation'
    | 'name'
    | 'workflowId'
  >;

  period?: Pick<Period, 'endDate' | 'frequency' | 'id' | 'name' | 'startDate'>;
};

export interface CreateStatementOverwriteRequest {
  readonly ruleId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly overwriteValue: RecordContentPropertyType;

  readonly field: string;

  readonly variableId: string;
}

export interface CreateDatasetOverwriteRequest {
  readonly scope: OverwriteScopeEnum;
  readonly overwriteValue: number | object | string | null;
  readonly rowExternalId?: string;
  readonly definitionId?: string;
  readonly field: string;
  readonly filterId?: string;
  readonly calculationType: CalculationType;
  readonly variableId: string;
}

export interface DeleteDatasetOverwriteRequest {
  readonly filterId: string;

  readonly definitionId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly calculationType: CalculationType;
}

export interface AddDatasetRowsRequest {
  readonly customObjectIds: string[];
}

// TODO: wtf is this? Why not a CurrencyValue?
export interface CurrencyAmount {
  amount: number | null;
  currencySymbol: CurrencySymbolsEnum;
  currencyRate?: number;
}

export interface Kpi {
  label?: string;
  value: CurrencyValue | number | null;
}

export interface MainKpi extends Kpi {
  type?: PaymentCategory | RuleType;
  hideTotal?: boolean;
  isSubMainKpi?: boolean;
  isForecastedView?: boolean;
  overrideFormattedValue?: string;
  formattedForecastedValue?: number | string;
  formattedOriginalValue?: number | string;
  isValueForecasted?: boolean;
  forecastedValue?: Kpi['value'];
  rate?: number;
}

export type AssignmentListScope = {
  [AssignmentType.MINE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.EMPLOYEE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MANAGER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MEMBER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
};

export type ListSelectedOptions = { team?: TeamOption; plan?: PlanOption };
