import { type CompanyCustomization, CompanyCustomizationDefault } from '@amalia/tenants/companies/types';

import { useCurrentCompany } from '../queries';

/**
 * Better be realistic with the type here, if a configuration option disappears for instance.
 */
export const useCompanyCustomization = (): Required<CompanyCustomization> => {
  const { data: company } = useCurrentCompany();

  return {
    holdAndReleasePaymentDateLabel: company.customization?.holdAndReleasePaymentDateLabel ?? '',
    holdAndReleaseForecastPaymentDateLabel: company.customization?.holdAndReleaseForecastPaymentDateLabel ?? '',
    totalCommissionLabel: company.customization?.totalCommissionLabel ?? '',
    reportsPrecision: company.customization?.reportsPrecision ?? CompanyCustomizationDefault.reportPrecision,
    forecastShowComparison:
      company.customization?.forecastShowComparison ?? CompanyCustomizationDefault.forecastShowComparison,
    forecastBelowActualColor:
      company.customization?.forecastBelowActualColor ?? CompanyCustomizationDefault.forecastBelowActualColor,
    forecastAboveActualColor:
      company.customization?.forecastAboveActualColor ?? CompanyCustomizationDefault.forecastAboveActualColor,
  };
};
