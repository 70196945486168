import { ClassNames, css } from '@emotion/react';
import { formatDate } from 'date-fns';
import { isFunction } from 'lodash';
import { type ForwardedRef, forwardRef, memo, type ReactElement, type ReactNode, useCallback, useState } from 'react';
import ReactDatePicker, { type DatePickerProps as ReactDatePickerProps } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';

import { useDateFnsLocaleContext } from '@amalia/ext/date-fns/components';
import { useForwardedRef } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Button } from '../../general/button/Button';
import { Popover, type PopoverProps } from '../../overlays/popover/Popover';
import { Input } from '../input/Input';

import { DatePickerBaseHeader } from './date-picker-base-header/DatePickerBaseHeader';
import * as styles from './DatePickerBase.styles';
import { type DatePickerValue, type DatePickerValueProps } from './DatePickerBase.types';
import { useMapDatePickerProps } from './hooks/useMapDatePickerProps';
import { useUtcDatePickerAdapter } from './hooks/useUtcDatePickerAdapter';

const YEARS_IN_PERIOD = 12;

export type DatePickerBaseProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    Pick<
      ReactDatePickerProps,
      | 'dateFormat'
      | 'disabled'
      | 'isClearable'
      | 'required'
      | 'showMonthYearPicker'
      | 'showQuarterYearPicker'
      | 'showTimeInput'
      | 'showYearPicker'
      | 'timeFormat'
      | 'wrapperClassName'
    >,
    Pick<PopoverProps, 'isOpen' | 'onChangeIsOpen' | 'placement'>,
    DatePickerValueProps<TWithRange>,
    {
      onChange?: (value: DatePickerValue<TWithRange>) => void;
      minDate?: Date | null;
      maxDate?: Date | null;
      /** Range mode. */
      selectsRange?: TWithRange;
      /** Custom input. If a function, will be passed the open state. */
      children: ReactElement | ((props: { isOpen: boolean; formattedValue: string }) => ReactElement);
      /** Custom clear button label. */
      clearButtonLabel?: ReactNode;
      /** Show clear button even if there is no value. */
      showClearButtonIfNoValue?: boolean;
      /** Convert all dates to UTC. onChange will be called with UTCDateMini instances. */
      isUtc?: boolean;
    },
  ]
>;

const DatePickerBaseForwardRef = forwardRef(function DatePickerBase<TWithRange extends boolean | undefined = undefined>(
  {
    dateFormat = 'P',
    timeFormat = 'p',
    isUtc = true,
    placement = 'bottom',
    selectsRange,
    isClearable,
    clearButtonLabel,
    showClearButtonIfNoValue,
    placeholder,
    showMonthYearPicker,
    showQuarterYearPicker,
    showYearPicker,
    children,
    isOpen: controlledIsOpen,
    onChangeIsOpen: controlledOnChangeIsOpen,
    value: propValue,
    endDate: propEndDate,
    startDate: propStartDate,
    minDate: propMinDate,
    maxDate: propMaxDate,
    onChange: propOnChange,
    ...props
  }: DatePickerBaseProps<TWithRange>,
  ref: ForwardedRef<ReactDatePicker>,
) {
  const { value, minDate, maxDate, onChange } = useUtcDatePickerAdapter<TWithRange>(
    {
      value: propValue,
      minDate: propMinDate,
      maxDate: propMaxDate,
      onChange: propOnChange,
      selectsRange,
    },
    isUtc,
  );

  const forwardedRef = useForwardedRef(ref);
  const { formatMessage } = useIntl();

  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState<boolean>(false);

  // The popover can be either controlled or uncontrolled.
  // If no isOpen prop is passed, fallback on internal state.
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;

  const handleChangeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      controlledOnChangeIsOpen?.(newIsOpen);
      setUncontrolledIsOpen(newIsOpen);
    },
    [controlledOnChangeIsOpen],
  );

  const { locale } = useIntl();
  const dateFnsLocale = useDateFnsLocaleContext();

  const hasValue = Array.isArray(value) ? value.some((date) => date !== null) : value !== null;

  // onChange passes the event as a second parameter so we ignore it by wrapping it.
  const handleChange = useCallback<NonNullable<typeof onChange>>(
    (value) => {
      onChange(value);

      // Close if not in range mode or if we picked a range.
      if (!selectsRange || (Array.isArray(value) && value.every((date) => date !== null))) {
        handleChangeIsOpen(false);
      }
    },
    [onChange, selectsRange, handleChangeIsOpen],
  );

  const handleResetValue = useCallback(() => {
    handleChange((selectsRange ? [null, null] : null) as Parameters<typeof handleChange>[0]);

    // There is a bug with the open prop because react-datepicker is dumb af so we need to do it manually :melting-face:.
    forwardedRef.current?.setOpen(false);
  }, [forwardedRef, handleChange, selectsRange]);

  const { startDate, endDate, placeholderText, selected } = useMapDatePickerProps({
    value,
    placeholder,
    endDate: propEndDate,
    startDate: propStartDate,
  });

  const renderCustomHeader: NonNullable<ReactDatePickerProps['renderCustomHeader']> = useCallback(
    (headerProps) => (
      <DatePickerBaseHeader
        {...headerProps}
        showMonthYearPicker={showMonthYearPicker}
        showQuarterYearPicker={showQuarterYearPicker}
        showYearPicker={showYearPicker}
      />
    ),
    [showMonthYearPicker, showQuarterYearPicker, showYearPicker],
  );

  return (
    <Popover
      shouldTriggerOnFocus
      isOpen={isOpen}
      placement={placement}
      content={
        <ClassNames>
          {(classNamesContent) => (
            // @ts-expect-error -- the way react-datepicker's types are declared in v7 do not allow us to wrap their component because they are idiots.
            <ReactDatePicker
              {...props}
              ref={forwardedRef}
              inline
              showFourColumnMonthYearPicker
              calendarClassName={styles.datePickerBasePopover(classNamesContent)}
              dateFormat={dateFormat}
              endDate={endDate}
              locale={locale}
              maxDate={maxDate}
              minDate={minDate}
              placeholderText={placeholderText}
              renderCustomHeader={renderCustomHeader}
              selected={selected}
              selectsMultiple={undefined}
              selectsRange={selectsRange || undefined}
              showMonthYearPicker={showMonthYearPicker}
              showPopperArrow={false}
              showQuarterYearPicker={showQuarterYearPicker}
              showYearPicker={showYearPicker}
              startDate={startDate}
              timeFormat={timeFormat}
              yearItemNumber={YEARS_IN_PERIOD}
              customTimeInput={
                <Input
                  size={Input.Size.SMALL}
                  type="time"
                />
              }
              timeCaption={formatMessage({
                defaultMessage: 'Time',
                description: 'Time as in time of day (hours, minutes etc)',
              })}
              onChange={handleChange as (value: unknown) => void}
            >
              {!!isClearable && !!(hasValue || showClearButtonIfNoValue) && (
                <Button
                  size={Button.Size.MEDIUM}
                  variant={Button.Variant.PRIMARY_TEXT}
                  css={css`
                    width: 100%;
                    justify-content: center;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                  `}
                  onClick={handleResetValue}
                >
                  {clearButtonLabel || <FormattedMessage defaultMessage="Clear value" />}
                </Button>
              )}
            </ReactDatePicker>
          )}
        </ClassNames>
      }
      onChangeIsOpen={handleChangeIsOpen}
    >
      {isFunction(children)
        ? children({
            isOpen,
            formattedValue: ([] as (Date | null)[])
              .concat(value)
              .filter(Boolean)
              .map((date) =>
                formatDate(date, Array.isArray(dateFormat) ? (dateFormat.at(0) ?? 'P') : dateFormat, {
                  locale: dateFnsLocale,
                }),
              )
              .join(' - '),
          })
        : children}
    </Popover>
  );
});

export const DatePickerBase = memo(DatePickerBaseForwardRef) as <TWithRange extends boolean | undefined = undefined>(
  props: DatePickerBaseProps<TWithRange> & { ref?: ForwardedRef<ReactDatePicker> },
) => ReactElement | null;
