import { type Period } from '@amalia/payout-definition/periods/types';

import { type AssignmentDates } from './types';

export const isAssignmentEffective = (assignment: AssignmentDates, period: Period) => {
  // If we have no range, planAssignment is always effective.
  if (!assignment.effectiveAsOf && !assignment.effectiveUntil) return true;

  // If we have only a startDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end.
  if (assignment.effectiveAsOf && !assignment.effectiveUntil) return assignment.effectiveAsOf <= period.endDate;

  // If we have both a startDate and an endDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end and the endDate is after the period start.
  // The check on the effectiveUntil is a dummy check to make sure that the effectiveUntil is after the effectiveAsOf.
  if (assignment.effectiveAsOf && assignment.effectiveUntil)
    return assignment.effectiveAsOf <= period.endDate && assignment.effectiveUntil >= period.startDate;

  // If we only have an endDate for the planAssignment, planAssignment is effective if
  // the endDate is after the period start.
  return !!assignment.effectiveUntil && assignment.effectiveUntil >= period.startDate;
};
