import { type FormatOptions } from '../../formatters/types';

export const printNullOrInfinity = (
  value: unknown,
  enabled: FormatOptions['printNullOrInfinity'],
  fallback: () => string,
) => {
  if (enabled) {
    if (value === null) {
      return 'null';
    }

    if (value === Infinity || value === 'Infinity') {
      return 'Infinity';
    }

    if (value === -Infinity || value === '-Infinity') {
      return '-Infinity';
    }
  }

  return fallback();
};
