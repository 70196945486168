import { css } from '@emotion/react';
import { memo, Suspense, type ReactNode, useState } from 'react';

import { CircularLoader, Group } from '@amalia/design-system/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { LayoutContext, type LayoutContextValue } from './Layout.context';
import { LeftBar, type LeftBarProps } from './left-bar/LeftBar';

export type LayoutProps = {
  readonly currentPage?: LeftBarProps['currentPage'];
  readonly children: ReactNode;
  readonly hideNavigationMenu?: boolean;
};

export const Layout = memo(function Layout({ currentPage, children, hideNavigationMenu }: LayoutProps) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [isPortalActive, setIsPortalActive] = useState(false);

  const layoutContextValue: LayoutContextValue = useShallowObjectMemo({ portalElement, setIsPortalActive });

  if (hideNavigationMenu) {
    return (
      <Suspense
        fallback={
          <Group
            align="center"
            justify="center"
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            `}
          >
            <CircularLoader size={CircularLoader.Size.LARGE} />
          </Group>
        }
      >
        {children}
      </Suspense>
    );
  }

  return (
    <div
      css={css`
        --left-menu-width: 164px;
        --right-drawer-width: ${isPortalActive ? 300 : 0}px;

        display: flex;
        height: 100%;
      `}
    >
      <LayoutContext.Provider value={layoutContextValue}>
        <LeftBar currentPage={currentPage} />

        <main
          css={(theme) => css`
            flex-grow: 1;
            margin-left: var(--left-menu-width);
            padding-right: var(--right-drawer-width);
            width: calc(100% - var(--left-menu-width) - var(--right-drawer-width));
            position: relative;

            transition: ${theme.ds.transitions.default('margin-left', 'padding-right')};
          `}
        >
          <Suspense
            fallback={
              <Group
                align="center"
                justify="center"
                css={css`
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                `}
              >
                <CircularLoader size={CircularLoader.Size.LARGE} />
              </Group>
            }
          >
            {children}
          </Suspense>
        </main>

        {/* This is the statement comment section, declared in CommentDrawerContainer */}
        <div ref={setPortalElement} />
      </LayoutContext.Provider>
    </div>
  );
});
