import { FormattedMessage } from 'react-intl';
import { match, P } from 'ts-pattern';

import { type Notification, NotificationsTypes } from '@amalia/core/types';

export const getNotificationText = (n: Notification) =>
  match(n)
    .with({ type: NotificationsTypes.NEW_COMMENT_ON_STATEMENT, contentMetadata: P.nonNullable }, (notification) =>
      notification.contentMetadata.statementUserFullName ? (
        <FormattedMessage
          defaultMessage="A statement of {statement_user_fullname} has been commented by {comment_author_fullname}"
          values={{
            statement_user_fullname: notification.contentMetadata.statementUserFullName,
            comment_author_fullname: notification.contentMetadata.commentAuthorFullName,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="You have been notified on one of your statements by {comment_author_fullname}"
          values={{ comment_author_fullname: notification.contentMetadata.commentAuthorFullName }}
        />
      ),
    )
    .with({ type: NotificationsTypes.NEW_EXPORT_AVAILABLE, contentMetadata: P.nonNullable }, (notification) =>
      notification.contentMetadata.isInError ? (
        <FormattedMessage defaultMessage="An error happened while exporting your data. Please contact your administrator to learn more." />
      ) : (
        <FormattedMessage defaultMessage="New export is available. Click here to download it." />
      ),
    )
    .with({ type: NotificationsTypes.REVIEW_STATEMENT, contentMetadata: P.nonNullable }, (notification) => (
      <FormattedMessage
        defaultMessage="Your statement on period {statement_period_name} was just reviewed by {reviewer_fullname}."
        values={{
          statement_period_name: notification.contentMetadata.statementPeriodName,
          reviewer_fullname: notification.contentMetadata.reviewerFullName,
        }}
      />
    ))
    .otherwise(() => n.content);
