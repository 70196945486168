import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { type ReactNode, memo, useCallback } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { DatePickerBase, type DatePickerBaseProps } from '../../../../data-input/date-picker-base/DatePickerBase';
import { type RowData } from '../../Table.types';

import { CellDatePickerAnchor, type CellDatePickerAnchorProps } from './cell-date-picker-anchor/CellDatePickerAnchor';

export type CellDatePickerProps<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData | undefined = undefined,
> = MergeAll<
  [
    Omit<DatePickerBaseProps<TWithRange>, 'children'>,
    {
      readonly row: TRowData;
      readonly error?: ReactNode;
      readonly warning?: ReactNode;
      readonly placeholder?: CellDatePickerAnchorProps['placeholder'];
      readonly clearButtonLabel?: CellDatePickerAnchorProps['clearButtonLabel'];
      readonly onChange?: (
        date: Parameters<NonNullable<DatePickerBaseProps<TWithRange>['onChange']>>[0],
        row: TRowData,
      ) => void;
    },
  ]
>;

const CellDatePickerBase = function CellDatePicker<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData | undefined = undefined,
>({
  row,
  placeholder,
  selectsRange,
  error,
  warning,
  isClearable = true,
  clearButtonLabel,
  onChange,
  ...props
}: CellDatePickerProps<TWithRange, TRowData>) {
  const handleChange: NonNullable<DatePickerBaseProps<TWithRange>['onChange']> = useCallback(
    (date) => onChange?.(date, row),
    [onChange, row],
  );

  const handleClear = useCallback(
    () =>
      onChange?.(
        (selectsRange ? [null, null] : null) as Parameters<NonNullable<DatePickerBaseProps<TWithRange>['onChange']>>[0],
        row,
      ),
    [onChange, row, selectsRange],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <DatePickerBase<TWithRange>
          {...props}
          clearButtonLabel={clearButtonLabel}
          isClearable={isClearable}
          selectsRange={selectsRange}
          wrapperClassName={clsx(
            props.wrapperClassName,
            css`
              &.react-datepicker-wrapper {
                display: block;
              }
            `,
          )}
          onChange={handleChange}
        >
          {({ isOpen: isCalendarOpen, formattedValue }) => (
            <CellDatePickerAnchor
              clearButtonLabel={clearButtonLabel}
              error={error}
              isCalendarOpen={isCalendarOpen}
              isClearable={isClearable}
              placeholder={placeholder}
              value={formattedValue}
              warning={warning}
              onClear={handleClear}
            />
          )}
        </DatePickerBase>
      )}
    </ClassNames>
  );
};

export const CellDatePicker = memo(CellDatePickerBase) as typeof CellDatePickerBase;
