import { css } from '@emotion/react';
import { memo, forwardRef, type ForwardedRef, cloneElement, useMemo, type MouseEvent } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../general/typography/Typography';
import { Group } from '../../../../layout/group/Group';
import { ColumnTooltip } from '../../column-helpers/column-tooltip/ColumnTooltip';
import { type ColumnDefinition } from '../../Table.types';
import { TableCell, type TableCellProps } from '../table-cell/TableCell';

import * as styles from './TableHeaderCell.styles';

export type TableHeaderCellProps = MergeAll<
  [
    Omit<TableCellProps, 'as'>,
    {
      subTitle?: ColumnDefinition['subTitle'];
      backgroundColor?: ColumnDefinition['headerBackgroundColor'];
      icon?: ColumnDefinition['icon'];
      tooltip?: ColumnDefinition['tooltip'];
      actions?: ColumnDefinition['actions'];
      labelIcon?: ColumnDefinition['labelIcon'];
      onRightClick?: ColumnDefinition['onRightClick'];
    },
  ]
>;

const TableHeaderCellForwardRef = forwardRef(function TableHeaderCell(
  {
    children,
    subTitle,
    backgroundColor,
    actions,
    icon,
    tooltip,
    labelIcon,
    onRightClick,
    ...props
  }: TableHeaderCellProps,
  ref: ForwardedRef<HTMLTableCellElement>,
) {
  const handleRightClick = useMemo(
    () =>
      onRightClick
        ? (e: MouseEvent<HTMLTableCellElement>) => {
            e.preventDefault();
            onRightClick();
          }
        : undefined,
    [onRightClick],
  );

  return (
    <TableCell
      {...props}
      ref={ref}
      as="th"
      css={[
        styles.tableHeaderCell,
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `,
      ]}
      onContextMenu={handleRightClick}
    >
      <div css={styles.tableHeaderCellContent}>
        <div css={styles.multiSelectTitleContainer}>
          <div
            css={styles.iconTitleContainer}
            data-has-subtitle={!!subTitle}
          >
            {!!icon && cloneElement(icon, { height: 16, width: 16 })}

            <div css={styles.titleContainer}>
              <div css={styles.titleTooltipContainer}>
                {labelIcon ? (
                  <Group
                    align="center"
                    gap={4}
                  >
                    {cloneElement(labelIcon, { height: 12, width: 12 })}
                    <TextOverflow>{children}</TextOverflow>
                  </Group>
                ) : (
                  <TextOverflow>{children}</TextOverflow>
                )}

                {/* If tooltip is a string, wrap inside a ColumnTooltip, otherwise consider that it is already a ColumnTooltip. */}
                {!!tooltip && (
                  <div css={styles.tooltipIconContainer}>
                    {typeof tooltip === 'string' ? <ColumnTooltip content={tooltip} /> : tooltip}
                  </div>
                )}
              </div>

              {!!subTitle && (
                <Typography
                  as={TextOverflow}
                  css={styles.subTitle}
                  variant={Typography.Variant.BODY_XSMALL_REGULAR}
                >
                  {subTitle}
                </Typography>
              )}
            </div>
          </div>
        </div>

        {actions}
      </div>
    </TableCell>
  );
});

export const TableHeaderCell = memo(TableHeaderCellForwardRef);
