import { LanguagesEnum } from '../languages';

export type IntlConfig<TLocale extends string> = {
  /** List of supported locales. */
  readonly locales: readonly TLocale[];
  /** Default locale. */
  readonly defaultLocale: TLocale;
  /** Name of each locale. Could be replaced with Intl.DisplayNames. */
  readonly localeLabels: Record<TLocale, string>;
};

export const intlConfig = {
  locales: Object.values(LanguagesEnum),
  defaultLocale: LanguagesEnum.en,
  localeLabels: {
    [LanguagesEnum.en]: 'English',
    [LanguagesEnum.fr]: 'Français',
    [LanguagesEnum.pt]: 'Português',
    [LanguagesEnum.de]: 'Deutsch',
    [LanguagesEnum.es]: 'Español',
    [LanguagesEnum.it]: 'Italiano',
  },
} as const satisfies IntlConfig<LanguagesEnum>;
