import { useEffect } from 'react';

/**
 * If the given flag is true, it will prevent the user from navigating away from the page, using the browser native navigation blocker.
 * This does not prevent in-app navigation, you should use `useBlocker` from `react-router-dom` along with this hook for that.
 */
export const useNativeUnsavedChanges = (shouldPreventNavigation = false) => {
  useEffect(() => {
    if (shouldPreventNavigation) {
      const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener('beforeunload', handler);
      return () => window.removeEventListener('beforeunload', handler);
    }

    return undefined;
  }, [shouldPreventNavigation]);
};
