import { css, useTheme } from '@emotion/react';
import { IconFileSad, IconFileSmile, IconFileUpload } from '@tabler/icons-react';
import { uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { type DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import { FormattedList, FormattedMessage, FormattedNumber } from 'react-intl';
import { match } from 'ts-pattern';

import { AlertBanner } from '../../feedback/alert-banner/AlertBanner';
import { Typography } from '../../general/typography/Typography';
import { Group } from '../../layout/group/Group';
import { Stack } from '../../layout/stack/Stack';

import { DroppedFile } from './dropped-file/DroppedFile';

export type FileDropzoneProps = Pick<DropzoneOptions, 'accept' | 'disabled' | 'maxFiles' | 'maxSize'> & {
  readonly files: File[];
  readonly onRemoveFile?: (file: File) => void;
  readonly onDropAccepted?: (files: File[]) => void;
  readonly error?: Error | null;
};

export const FileDropzone = memo(function FileDropzone({
  files,
  error,
  onRemoveFile,
  maxFiles = Infinity,
  maxSize = Infinity,
  ...props
}: FileDropzoneProps) {
  const theme = useTheme();

  const availableFilesCount = Math.max(0, maxFiles - files.length);
  const isSuccess = files.length && !availableFilesCount;

  const acceptedExtensions = useMemo(() => uniq(Object.values(props.accept ?? {}).flat()), [props.accept]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
    ...props,
    maxSize,
    multiple: availableFilesCount > 1,
    maxFiles: availableFilesCount,
  });

  const displayedError = useMemo(
    () =>
      error?.message ??
      match(fileRejections.at(0)?.errors.at(0)?.code)
        .with(ErrorCode.FileInvalidType, () => <FormattedMessage defaultMessage="Invalid file type" />)
        .with(ErrorCode.FileTooLarge, () => <FormattedMessage defaultMessage="File is too large" />)
        .with(ErrorCode.FileTooSmall, () => <FormattedMessage defaultMessage="File is too small" />)
        .with(ErrorCode.TooManyFiles, () => <FormattedMessage defaultMessage="Too many uploaded files" />)
        .otherwise(() => fileRejections.at(0)?.errors.at(0)?.message),
    [error, fileRejections],
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Stack
        gap={80}
        justify="space-between"
        css={(theme) => css`
          width: 100%;
          padding: 60px 24px ${acceptedExtensions.length && Number.isFinite(maxSize) ? '16px' : '60px'};
          border-radius: ${theme.ds.borderRadiuses.squared};
          cursor: pointer;
          border: 1px dashed
            ${isDragReject
              ? theme.ds.colors.danger[500]
              : isDragAccept
                ? theme.ds.colors.success[500]
                : theme.ds.colors.gray[300]};
        `}
      >
        <Stack
          align="center"
          gap={16}
        >
          <Stack
            align="center"
            gap={12}
          >
            {isDragAccept || isSuccess ? (
              <IconFileSmile
                color={theme.ds.colors.success[500]}
                size={40}
              />
            ) : isDragReject || displayedError ? (
              <IconFileSad
                color={theme.ds.colors.danger[500]}
                size={40}
              />
            ) : (
              <IconFileUpload
                color={theme.ds.colors.primary[400]}
                size={40}
              />
            )}

            {displayedError ? (
              <AlertBanner variant={AlertBanner.Variant.ERROR}>{displayedError}</AlertBanner>
            ) : (
              !isDragActive &&
              !!isSuccess && (
                <AlertBanner variant={AlertBanner.Variant.SUCCESS}>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {File} other {Files}} uploaded succesfully"
                    values={{ count: files.length }}
                  />
                </AlertBanner>
              )
            )}
          </Stack>

          {displayedError ? (
            <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
              <FormattedMessage defaultMessage="Drop another file here or click to browse" />
            </Typography>
          ) : (
            !!(isDragActive || !isSuccess) && (
              <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
                <FormattedMessage
                  defaultMessage="Drop {count, plural, one {file} other {files}} here or click to browse"
                  values={{ count: availableFilesCount }}
                />
              </Typography>
            )
          )}
        </Stack>

        {files.map((file) => (
          <DroppedFile
            key={file.name}
            file={file}
            onRemove={onRemoveFile}
          />
        ))}

        {!!availableFilesCount && !!(acceptedExtensions.length || Number.isFinite(maxSize)) && (
          <Group justify="space-between">
            {acceptedExtensions.length ? (
              <Typography
                variant={Typography.Variant.BODY_SMALL_REGULAR}
                css={(theme) => css`
                  color: ${theme.ds.colors.gray[700]};
                `}
              >
                <FormattedMessage
                  defaultMessage="Supported formats: {extensions}"
                  values={{
                    extensions: (
                      <FormattedList
                        style="short"
                        type="unit"
                        value={acceptedExtensions}
                      />
                    ),
                  }}
                />
              </Typography>
            ) : (
              <div />
            )}

            {!!Number.isFinite(maxSize) && (
              <Typography
                variant={Typography.Variant.BODY_SMALL_REGULAR}
                css={(theme) => css`
                  color: ${theme.ds.colors.gray[700]};
                `}
              >
                <FormattedMessage
                  defaultMessage="Maximum size: {size}"
                  values={{
                    size: (
                      <FormattedNumber
                        maximumFractionDigits={1}
                        notation="compact"
                        style="unit"
                        unit="byte"
                        unitDisplay="narrow"
                        value={maxSize}
                      />
                    ),
                  }}
                />
              </Typography>
            )}
          </Group>
        )}
      </Stack>
    </div>
  );
});
