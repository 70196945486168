import { type Rule } from '@amalia/payout-definition/plans/types';
import { type PlanAgreement } from '@amalia/plan-agreements/types';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';
import { type DataExport } from '@amalia/reporting/exports/shared';

import { type Statement } from './statement';

export enum NotificationsTypes {
  NEW_COMMENT_ON_STATEMENT = 'NEW_COMMENT_ON_STATEMENT',
  NEW_EXPORT_AVAILABLE = 'NEW_EXPORT_AVAILABLE',
  NEW_PLAN_AGREEMENT = 'NEW_PLAN_AGREEMENT',
  REVIEW_STATEMENT = 'REVIEW_STATEMENT',
  WORKFLOW_PROCESS_FINISHED = 'WORKFLOW_PROCESS_FINISHED',
  CHALLENGE_LAUNCHED = 'CHALLENGE_LAUNCHED',
  DASHBOARD_SHARED = 'DASHBOARD_SHARED',
}

type NotificationBase = {
  id: string;
  createdAt: Date;
  content: string;
  link: string | null;
  read: boolean;
  authorId: string | null;
};

export type NewCommentOnStatementNotificationContent = {
  type: NotificationsTypes.NEW_COMMENT_ON_STATEMENT;
  contentMetadata: {
    statementId: Statement['id'];
    statementUserFullName?: string;
    commentAuthorFullName: string;
  };
  payload?: null;
};

export type NewCommentOnStatementNotification = NewCommentOnStatementNotificationContent & NotificationBase;

export type NewExportAvailableNotificationContent = {
  type: NotificationsTypes.NEW_EXPORT_AVAILABLE;
  contentMetadata: {
    dataExportId: DataExport['id'];
    isInError: boolean;
  };
  payload: {
    exportId: DataExport['id'];
  } | null; // nullable because we use the same notification type for failure for some reason.
};

export type NewExportAvailableNotification = NewExportAvailableNotificationContent & NotificationBase;

export type NewPlanAgreementNotificationContent = {
  type: NotificationsTypes.NEW_PLAN_AGREEMENT;
  contentMetadata: {
    planAgreementId: PlanAgreement['id'];
    planAgreementName: PlanAgreement['name'];
  };
  payload?: null;
};

export type NewPlanAgreementNotification = NewPlanAgreementNotificationContent & NotificationBase;

export type ReviewStatementNotificationContent = {
  type: NotificationsTypes.REVIEW_STATEMENT;
  contentMetadata: {
    statementId: Statement['id'];
    statementPeriodName: string;
    reviewerFullName: string;
  };
  payload: {
    statementId: Statement['id'];
  };
};

export type ReviewStatementNotification = NotificationBase & ReviewStatementNotificationContent;

export type WorkflowProcessFinishedNotificationContent = {
  type: NotificationsTypes.WORKFLOW_PROCESS_FINISHED;
  contentMetadata?: null;
  payload?: null;
};

export type WorkflowProcessFinishedNotification = NotificationBase & WorkflowProcessFinishedNotificationContent;

export type ChallengeLaunchedNotificationContent = {
  type: NotificationsTypes.CHALLENGE_LAUNCHED;
  contentMetadata: {
    challengeRuleId: Rule['id'];
  };
  payload?: null;
};

export type ChallengeLaunchedNotification = ChallengeLaunchedNotificationContent & NotificationBase;

export type DashboardSharedNotificationContent = {
  type: NotificationsTypes.DASHBOARD_SHARED;
  contentMetadata: {
    dashboardId: Dashboard['id'];
  };
  payload?: null;
};

export type DashboardSharedNotification = DashboardSharedNotificationContent & NotificationBase;

export type NotificationContent =
  | ChallengeLaunchedNotificationContent
  | DashboardSharedNotificationContent
  | NewCommentOnStatementNotificationContent
  | NewExportAvailableNotificationContent
  | NewPlanAgreementNotificationContent
  | ReviewStatementNotificationContent
  | WorkflowProcessFinishedNotificationContent;

export type Notification =
  | ChallengeLaunchedNotification
  | DashboardSharedNotification
  | NewCommentOnStatementNotification
  | NewExportAvailableNotification
  | NewPlanAgreementNotification
  | ReviewStatementNotification
  | WorkflowProcessFinishedNotification;

export type NotificationPayload =
  | NonNullable<NewExportAvailableNotification['payload']>
  | ReviewStatementNotification['payload'];

export type NotificationContentMetadata =
  | ChallengeLaunchedNotification['contentMetadata']
  | DashboardSharedNotification['contentMetadata']
  | NewCommentOnStatementNotification['contentMetadata']
  | NewExportAvailableNotification['contentMetadata']
  | NewPlanAgreementNotification['contentMetadata']
  | ReviewStatementNotification['contentMetadata'];
