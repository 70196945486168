import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';
import { managerCanAccessStatement } from '../statements/definitions';
import { type StatementSubject } from '../statements/subjects';

import { type CalculateForecastedStatementSubject, type SimulateForecastedStatementSubject } from './subjects';

export const forecastedStatementsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement);
  },

  MANAGER(authenticatedContext, { can }) {
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
  },

  EMPLOYEE({ user }, { can }) {
    can(
      ActionsEnum.simulate,
      SubjectsEnum.Forecasted_Statement,
      ({ userId }: SimulateForecastedStatementSubject) => userId === user.id,
    );

    can(
      ActionsEnum.calculate,
      SubjectsEnum.Forecasted_Statement,
      ({ userId }: CalculateForecastedStatementSubject) => userId === user.id,
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
