import { css } from '@emotion/react';
import {
  IconBrush,
  IconLockDollar,
  IconPlugConnected,
  IconReportMoney,
  IconSettings,
  IconTopologyBus,
} from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation } from 'react-router-dom';

import { Layout, PageContainer } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { PageHeader, Paper, SideMenu, Typography } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useFeatureFlag } from '@amalia/tenants/companies/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

import { CompanyRoutes } from './company.routes';

const MENU_WIDTH = 164;

export const CompanySettingsView = memo(function CompanySettingsView() {
  const location = useLocation();
  const ability = useAbilityContext();
  const { isFeatureEnabled: isPaymentsLockEnabled } = useFeatureFlag(CompanyFeatureFlags.PAYMENTS_LOCK);

  return (
    <Layout currentPage="companySettings">
      <PageContainer
        css={css`
          display: flex;
          flex-direction: column;
          min-height: 100%;
        `}
      >
        <PageHeader>
          <PageHeader.Row
            left={
              <Typography variant={Typography.Variant.HEADING_1_BOLD}>
                <FormattedMessage defaultMessage="Company settings" />
              </Typography>
            }
          />
        </PageHeader>

        <Paper
          css={css`
            margin-top: 32px;
            display: flex;
            flex: 1;
          `}
        >
          <SideMenu
            value={location.pathname}
            css={css`
              flex: none;
              width: ${MENU_WIDTH}px;
            `}
          >
            <SideMenu.Group>
              {ability.can(ActionsEnum.view_settings, SubjectsEnum.Company) && (
                <SideMenu.Item
                  icon={<IconSettings />}
                  to={generatePath(routes.COMPANY_SETTINGS)}
                  value={generatePath(routes.COMPANY_SETTINGS)}
                >
                  <FormattedMessage defaultMessage="Main settings" />
                </SideMenu.Item>
              )}

              {ability.can(ActionsEnum.view, SubjectsEnum.ApiKeys) && (
                <SideMenu.Item
                  icon={<IconPlugConnected />}
                  to={generatePath(routes.COMPANY_API)}
                  value={generatePath(routes.COMPANY_API)}
                >
                  <FormattedMessage defaultMessage="API settings" />
                </SideMenu.Item>
              )}

              {ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) && (
                <SideMenu.Item
                  icon={<IconReportMoney />}
                  to={generatePath(routes.COMPANY_RATES)}
                  value={generatePath(routes.COMPANY_RATES)}
                >
                  <FormattedMessage defaultMessage="Currency rates" />
                </SideMenu.Item>
              )}

              {ability.can(ActionsEnum.modify, SubjectsEnum.Workflow) && (
                <SideMenu.Item
                  icon={<IconTopologyBus />}
                  to={generatePath(routes.COMPANY_WORKFLOWS)}
                  value={generatePath(routes.COMPANY_WORKFLOWS)}
                >
                  <FormattedMessage defaultMessage="Workflows" />
                </SideMenu.Item>
              )}

              {!!isPaymentsLockEnabled && ability.can(ActionsEnum.modify_settings, SubjectsEnum.Company) && (
                <SideMenu.Item
                  icon={<IconLockDollar />}
                  to={generatePath(routes.COMPANY_PAYMENTS_LOCK)}
                  value={generatePath(routes.COMPANY_PAYMENTS_LOCK)}
                >
                  <FormattedMessage defaultMessage="Payment locks" />
                </SideMenu.Item>
              )}

              {ability.can(ActionsEnum.view_settings, SubjectsEnum.Company) && (
                <SideMenu.Item
                  icon={<IconBrush />}
                  to={generatePath(routes.COMPANY_CUSTOMIZATION)}
                  value={generatePath(routes.COMPANY_CUSTOMIZATION)}
                >
                  <FormattedMessage defaultMessage="Customization" />
                </SideMenu.Item>
              )}
            </SideMenu.Group>
          </SideMenu>

          <div
            css={css`
              flex: 1;
              width: calc(100% - ${MENU_WIDTH}px);
            `}
          >
            <CompanyRoutes />
          </div>
        </Paper>
      </PageContainer>
    </Layout>
  );
});
