import { css, type Theme } from '@emotion/react';
import { memo, type PropsWithChildren, useEffect } from 'react';

import { Paper } from '@amalia/design-system/components';
import { Portal } from '@amalia/ext/react/components';

import { useLayoutContext } from '../Layout.context';

const rootContainer = (theme: Theme) => css`
  --right-menu-offset: 8px;

  width: calc(var(--right-drawer-width) - var(--right-menu-offset));
  overflow: auto;

  position: fixed;
  top: var(--right-menu-offset);
  bottom: var(--right-menu-offset);
  right: var(--right-menu-offset);
  z-index: 1;

  display: flex;
  flex: 1;

  // add animation when sidebar is opened
  animation: slide-in ${theme.ds.transitions.default.durationMs}ms ${theme.ds.transitions.default.easing};
  @keyframes slide-in {
    from {
      transform: translateX(150%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const RightSidebarPortal = memo(function RightSidebarPortal({ children }: PropsWithChildren) {
  const { portalElement, setIsPortalActive } = useLayoutContext();

  useEffect(() => {
    setIsPortalActive(!!children);
  }, [children, setIsPortalActive]);

  useEffect(
    () =>
      /** When Portal is unmount, make sure we do not let the right padding in place */
      () =>
        setIsPortalActive(false),
    [setIsPortalActive],
  );

  if (!children) {
    return null;
  }

  return (
    <Portal element={portalElement}>
      <Paper
        css={rootContainer}
        id="right-sidebar-container"
      >
        {children}
      </Paper>
    </Portal>
  );
});
