import { isEmpty } from 'lodash';

import { http } from '@amalia/core/http/client';
import { type Statement } from '@amalia/core/types';
import {
  type DataExport,
  type DataExportParams,
  type DataExportRequest,
  DownloadType,
} from '@amalia/reporting/exports/shared';

import { DownloadsApiClient } from './downloads.api-client';

const apiEndpoint = '/data_exports';

export class DataExportsApiClient {
  /**
   * Create an export.
   *
   * @param dataExport
   */
  public static async createDataExport(dataExport: DataExportRequest): Promise<DataExport> {
    const { data } = await http.post<DataExport>(`${apiEndpoint}`, {
      ...dataExport,
      params: {
        ...dataExport.params,
        // Do not send params if selection is empty.
        periodIds: isEmpty(dataExport.params.periodIds) ? undefined : dataExport.params.periodIds,
        planIds: isEmpty(dataExport.params.planIds) ? undefined : dataExport.params.planIds,
        ruleIds: isEmpty(dataExport.params.ruleIds) ? undefined : dataExport.params.ruleIds,
        teamIds: isEmpty(dataExport.params.teamIds) ? undefined : dataExport.params.teamIds,
        userIds: isEmpty(dataExport.params.userIds) ? undefined : dataExport.params.userIds,
        paymentPeriodIds: isEmpty(dataExport.params.paymentPeriodIds) ? undefined : dataExport.params.paymentPeriodIds,
        currencies: isEmpty(dataExport.params.currencies) ? undefined : dataExport.params.currencies,
        ruleTypes: isEmpty(dataExport.params.ruleTypes) ? undefined : dataExport.params.ruleTypes,
      },
    });
    return data;
  }

  /**
   * Get the list of statements in error in the given criteria.
   *
   * @param dataExportParams
   */
  public static async getStatementsInError(dataExportParams: DataExportParams): Promise<Statement[]> {
    const { data } = await http.post<Statement[]>(`${apiEndpoint}/in-error`, {
      periodIds: isEmpty(dataExportParams.periodIds) ? undefined : dataExportParams.periodIds,
      planIds: isEmpty(dataExportParams.planIds) ? undefined : dataExportParams.planIds,
      teamIds: isEmpty(dataExportParams.teamIds) ? undefined : dataExportParams.teamIds,
      userIds: isEmpty(dataExportParams.userIds) ? undefined : dataExportParams.userIds,
    });

    return data;
  }

  /**
   * Open a new tab to download the generated file.
   *
   * @param exportId
   */
  public static async downloadDataExportFile(exportId: string) {
    return DownloadsApiClient.downloadFileViaToken({
      type: DownloadType.DATA_EXPORT,
      dataId: exportId,
    });
  }
}
