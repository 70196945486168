import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { memo, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Skeleton, TextLink, Tooltip } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import {
  IconAmalia,
  IconAmaliaAprilFools,
  IconAmaliaChristmas,
  IconAmaliaEaster,
  IconAmaliaHalloween,
  IconAmaliaPinktober,
} from '@amalia/frontend/ui-icons';
import { NotificationsWidget } from '@amalia/tenants/users/notifications/components';

const today = new Date();

const { logo, tooltip } = (() => {
  switch (true) {
    // Christmas
    case today.getMonth() === 11:
      return {
        logo: (
          <IconAmaliaChristmas
            css={css`
              position: absolute;
              left: 0;
              bottom: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: <FormattedMessage defaultMessage="Happy holidays!" />,
      };

    // Easter (manually update the date each year or wait until the Vatican exposes a rest API)
    case Math.abs(dayjs().diff('2025-04-21', 'days')) < 3:
      return {
        logo: (
          <IconAmaliaEaster
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: null,
      };

    // Pinktober
    case today.getMonth() === 9 && today.getDate() <= 15:
      return {
        logo: (
          <IconAmaliaPinktober
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: (
          <FormattedMessage
            defaultMessage="October marks Pinktober, a month dedicated to raising awareness about breast cancer and the vital importance of early detection.{br}You can learn more <link>here</link>."
            values={{
              link: (chunks) => (
                <TextLink
                  colorScheme="light"
                  to="https://www.service-public.fr/particuliers/actualites/A15216?lang=en"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        ),
      };

    // Halloween
    case (today.getMonth() === 9 && today.getDate() >= 26) || (today.getMonth() === 10 && today.getDate() <= 3):
      return {
        logo: (
          <IconAmaliaHalloween
            css={css`
              position: absolute;
              left: 0;
              bottom: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: <FormattedMessage defaultMessage="Booh!" />,
      };

    // April fools
    case today.getMonth() === 3 && today.getDate() === 1:
      return {
        logo: (
          <IconAmaliaAprilFools
            css={css`
              position: absolute;
              left: 0;
              bottom: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: <FormattedMessage defaultMessage="April fools!" />,
      };

    // Default
    default:
      return {
        logo: (
          <IconAmalia
            css={css`
              position: absolute;
              left: 0;
              bottom: 0;
              height: auto;
              width: 100%;
            `}
          />
        ),
        tooltip: null,
      };
  }
})();

export const LeftBarHeader = memo(function LeftBarHeader() {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Tooltip
        content={tooltip}
        placement="right"
      >
        {/* Special logos do not have the same viewbox as the regular logo, so we keep the same box size and we position the logo manually inside that. */}
        <Link
          to={generatePath(routes.ROOT)}
          css={css`
            display: flex;
            height: 17px;
            width: 90px;
            position: relative;
          `}
        >
          <Suspense
            fallback={
              <Skeleton
                css={css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                `}
              />
            }
          >
            {logo}
          </Suspense>
        </Link>
      </Tooltip>

      <NotificationsWidget />
    </div>
  );
});
