import { type Company } from '@amalia/tenants/companies/types';

export const superadminQueryKeys = {
  all: () => ['superadmin'] as const,
  companies: {
    all: () => [...superadminQueryKeys.all(), 'companies'] as const,
    list: () => [...superadminQueryKeys.companies.all(), 'list'] as const,
    details: (companyId: Company['id']) => [...superadminQueryKeys.companies.all(), companyId] as const,
  } as const,
  users: {
    all: () => [...superadminQueryKeys.all(), 'users'] as const,
    list: (companyId: Company['id']) => [...superadminQueryKeys.users.all(), 'list', companyId] as const,
  },
  calculations: {
    all: () => [...superadminQueryKeys.all(), 'calculations'] as const,
    ongoing: () => [...superadminQueryKeys.calculations.all(), 'ongoing'] as const,
    last: (companyIds: Company['id'][], filterErrors: boolean, page: number) =>
      [...superadminQueryKeys.calculations.all(), 'last', companyIds, filterErrors, page] as const,
  },
  dataUpdates: {
    all: () => [...superadminQueryKeys.all(), 'data-updates'] as const,
    ongoing: () => [...superadminQueryKeys.dataUpdates.all(), 'ongoing'] as const,
    last: (companyIds: Company['id'][], filterErrors: boolean, page: number) =>
      [...superadminQueryKeys.dataUpdates.all(), 'last', companyIds, filterErrors, page] as const,
  },
  periods: {
    all: () => [...superadminQueryKeys.all(), 'periods'] as const,
    list: (companyId: Company['id']) => [...superadminQueryKeys.periods.all(), 'list', companyId] as const,
  },
  connectors: {
    all: () => [...superadminQueryKeys.all(), 'connectors'] as const,
    list: (companyIds: Company['id'][]) => [...superadminQueryKeys.connectors.all(), 'list', companyIds] as const,
  },
  statements: {
    all: () => [...superadminQueryKeys.all(), 'statements'] as const,
    inError: (companyIds: Company['id'][]) =>
      [...superadminQueryKeys.statements.all(), 'in-error', companyIds] as const,
  },
  plans: {
    all: () => [...superadminQueryKeys.all(), 'plans'] as const,
    list: (companyId: Company['id']) => [...superadminQueryKeys.plans.all(), 'list', companyId] as const,
  },
  billing: {
    all: () => [...superadminQueryKeys.all(), 'billing'] as const,
    licences: (companyId: Company['id'], date: Date) =>
      [...superadminQueryKeys.billing.all(), 'licences', companyId, date] as const,
  },
  masterSettings: () => [...superadminQueryKeys.all(), 'master-settings'] as const,
} as const;
