import clsx from 'clsx';
import { memo, useCallback } from 'react';

import { UnstyledButton } from '../../../../general/unstyled-button/UnstyledButton';
import { DatePickerBase } from '../../DatePickerBase';
import * as styles from '../DatePickerBaseHeader.styles';

export type DatePickerBaseHeaderYearPickerProps = {
  readonly value: Date;
  readonly onChange: (year: number) => void;
};

export const DatePickerBaseHeaderYearPicker = memo(function DatePickerBaseHeaderYearPicker({
  value,
  onChange,
}: DatePickerBaseHeaderYearPickerProps) {
  const handleChange = useCallback((date: Date | null) => onChange(date!.getFullYear()), [onChange]);

  // Yo dawg, I heard you like date pickers so I put a date picker in your date picker.
  return (
    <DatePickerBase
      showYearPicker
      dateFormat="yyyy"
      value={value}
      onChange={handleChange}
    >
      {({ isOpen, formattedValue }) => (
        <UnstyledButton
          className={clsx({ [styles.IS_ACTIVE_CLASSNAME]: isOpen })}
          css={styles.datePickerHeaderButton}
          data-testid="date-picker-year-selector"
        >
          {formattedValue}
        </UnstyledButton>
      )}
    </DatePickerBase>
  );
});
