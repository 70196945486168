import { http } from '@amalia/core/http/client';
import {
  type RuleTemplateSingleRateCommissionResponse,
  type Plan,
  type Rule,
  type RuleTemplateSingleRateCommission,
} from '@amalia/payout-definition/plans/types';

export class RuleTemplatesApiClient {
  public static async applySingleRateCommissionTemplateToRule(
    planId: Plan['id'],
    ruleId: Rule['id'],
    ruleTemplateSingleRateCommissionDTO: RuleTemplateSingleRateCommission,
  ): Promise<RuleTemplateSingleRateCommissionResponse> {
    const response = await http.post<RuleTemplateSingleRateCommissionResponse>(
      `/plans/${encodeURIComponent(planId)}/rules/${encodeURIComponent(ruleId)}/templates/single-rate-commission`,
      ruleTemplateSingleRateCommissionDTO,
    );

    return response.data;
  }
}
