import { css } from '@emotion/react';
import { IconTrash } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import ReactCropper, { type ReactCropperElement } from 'react-cropper';
import { useIntl } from 'react-intl';

import { FileDropzone, IconButton } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { avatarEditorStyle } from './AvatarEditor.styles';

type AvatarEditorProps = {
  readonly onCrop: (croppedPictureBase64: string) => void;
};

export const AvatarEditor = memo(function AvatarEditor({ onCrop }: AvatarEditorProps) {
  const { formatMessage } = useIntl();
  const cropperRef = useRef<ReactCropperElement>(null);

  const [picture, setPicture] = useState<string | null>(null);

  const {
    isPending,
    error,
    mutate: handleDrop,
  } = useMutation({
    mutationFn: ([file]: File[]) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () =>
          reject(
            new Error(
              formatMessage(
                { defaultMessage: 'Could not load picture: {errorMessage}.' },
                { errorMessage: toError(reader.error).message },
              ),
            ),
          );

        reader.readAsDataURL(file);
      }).then(setPicture),
  });

  const handleRemovePicture = useCallback(() => setPicture(null), []);

  const handleCrop = useMemo(
    () => debounce(() => onCrop(cropperRef.current!.cropper.getCroppedCanvas().toDataURL()), 100),
    [onCrop],
  );

  return picture ? (
    <div
      css={css`
        position: relative;
      `}
    >
      <ReactCropper
        ref={cropperRef}
        autoCrop
        movable
        aspectRatio={1}
        autoCropArea={1}
        crop={handleCrop}
        css={avatarEditorStyle}
        dragMode="move"
        rotatable={false}
        src={picture}
        viewMode={2}
        style={{
          height: 256,
          width: 536,
        }}
      />

      <IconButton
        withBackground
        icon={<IconTrash />}
        label={formatMessage({ defaultMessage: 'Remove' })}
        variant={IconButton.Variant.DANGER}
        css={css`
          position: absolute;
          top: 12px;
          right: 12px;
        `}
        onClick={handleRemovePicture}
      />
    </div>
  ) : (
    <FileDropzone
      disabled={isPending}
      error={error}
      files={[]}
      maxFiles={1}
      maxSize={5_000_000}
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/svg+xml': ['.svg'],
      }}
      onDropAccepted={handleDrop}
    />
  );
});
