import { assert } from '@amalia/ext/typescript';
import { type AuthenticatedContext } from '@amalia/kernel/auth/types';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ReviewStatementThreadSubject, type StatementSubject } from './subjects';

const nonAdminCanAccessPlan = (plan: Pick<Plan, 'isHidden'>) => plan.isHidden === false;

export const managerCanAccessStatement = (authenticatedContext: AuthenticatedContext, statement: StatementSubject) => {
  const userId = statement.user?.id ?? statement.userId;

  assert(statement.plan, 'Statement plan needs to be populated.');
  assert(statement.period, 'Statement period needs to be populated.');
  assert(userId, 'Statement user or userId needs to be populated.');

  return (
    nonAdminCanAccessPlan(statement.plan) &&
    // It's my statement.
    (authenticatedContext.user.id === userId ||
      // I was manager of the employee at the beginning of the period.
      authenticatedContext.hierarchy.isManagerOf(userId, statement.period.startDate) ||
      // I was manager of the employee at the end of the period.
      authenticatedContext.hierarchy.isManagerOf(userId, statement.period.endDate))
  );
};

const employeeCanAccessStatement = (authenticatedContext: AuthenticatedContext, statement: StatementSubject) =>
  !!statement.plan && nonAdminCanAccessPlan(statement.plan) && authenticatedContext.user.id === statement.userId;

export const statementsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.review, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.adjust, SubjectsEnum.Statement);
    can(ActionsEnum.overwrite, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement);
    can(ActionsEnum.review_statement_thread, SubjectsEnum.Statement, () => true);

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement);

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, {
      predicate: () => true,
      subset: SubsetAccessEnum.EVERYTHING,
    });

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },

  MANAGER(authenticatedContext, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, {
      predicate: (statement: StatementSubject) => managerCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.review, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.calculate, SubjectsEnum.Statement, {
      predicate: (statement: StatementSubject) => managerCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.adjust, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.overwrite, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.customize, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.review_statement_thread, SubjectsEnum.Statement, ({ statement }: ReviewStatementThreadSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },

  READ_ONLY_MANAGER(authenticatedContext, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, {
      predicate: (statement: StatementSubject) => managerCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.customize, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement, (statement: StatementSubject) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(
      ActionsEnum.review_statement_thread,
      SubjectsEnum.Statement,
      ({ statement, isReviewed }: ReviewStatementThreadSubject) =>
        isReviewed === false && managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },

  EMPLOYEE(authenticatedContext, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, {
      predicate: (statement: StatementSubject) => employeeCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MINE,
    });

    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.customize, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );

    can(
      ActionsEnum.view_statement_threads,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: StatementSubject) =>
        userId === authenticatedContext.user.id || statementUser?.id === authenticatedContext.user.id,
    );
    can(
      ActionsEnum.add_statement_comments,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: StatementSubject) =>
        userId === authenticatedContext.user.id || statementUser?.id === authenticatedContext.user.id,
    );

    // I can unreview a thread on my statement.
    can(
      ActionsEnum.review_statement_thread,
      SubjectsEnum.Statement,
      ({ statement, isReviewed }: ReviewStatementThreadSubject) =>
        (statement.user?.id || statement.userId) === authenticatedContext.user.id && isReviewed === false,
    );

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },

  READ_ONLY_EMPLOYEE(authenticatedContext, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, {
      predicate: (statement: StatementSubject) => employeeCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MINE,
    });
    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );

    can(
      ActionsEnum.view_statement_threads,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: StatementSubject) =>
        userId === authenticatedContext.user.id || statementUser?.id === authenticatedContext.user.id,
    );

    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },

  DEACTIVATED_USER(authenticatedContext, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: StatementSubject) =>
      employeeCanAccessStatement(authenticatedContext, statement),
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
