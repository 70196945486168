import { type ForwardedRef, memo, forwardRef, type ReactElement, useCallback } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { DatePickerBase, type DatePickerBaseProps } from '../date-picker-base/DatePickerBase';
import { type InputProps } from '../input/Input';
import { InputSize } from '../input/Input.types';
import { useFormContext } from '../meta/form-context';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';

import { DatePickerInput } from './date-picker-input/DatePickerInput';
import { datePickerTestIds } from './DatePicker.testIds';

export type DatePickerProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [Omit<DatePickerBaseProps<TWithRange>, 'children'>, UseFormFieldPropsOptions, Pick<InputProps, 'name' | 'size'>]
>;

const DatePickerForwardRef = forwardRef(function DatePicker<TWithRange extends boolean | undefined = undefined>(
  { size, isClearable = true, disabled: propsDisabled, ...props }: DatePickerProps<TWithRange>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { disabled: formContextDisabled } = useFormContext();

  const {
    formFieldProps,
    otherProps: { onChange, selectsRange, name, placeholder, ...otherProps },
  } = useFormFieldProps({ ...props, disabled: propsDisabled || formContextDisabled });

  const onClear = useCallback(
    () => onChange?.((selectsRange ? [null, null] : null) as Parameters<typeof onChange>[0]),
    [onChange, selectsRange],
  );

  return (
    <DatePickerBase<TWithRange>
      {...otherProps}
      isClearable={isClearable}
      required={formFieldProps.required} // ReactDatePicker overrides Input's required prop so we need to pass it here.
      selectsRange={selectsRange}
      onChange={onChange}
    >
      {({ formattedValue }) => (
        <DatePickerInput
          {...formFieldProps}
          clearButtonLabel={props.clearButtonLabel}
          data-testid={datePickerTestIds.input(formFieldProps.id)}
          innerRef={ref}
          isClearable={isClearable}
          name={name}
          placeholder={placeholder}
          selectsRange={selectsRange}
          size={size}
          value={formattedValue}
          onClear={onClear}
        />
      )}
    </DatePickerBase>
  );
});

export const DatePicker = Object.assign(
  memo(DatePickerForwardRef) as <TWithRange extends boolean | undefined = undefined>(
    props: DatePickerProps<TWithRange> & { ref?: ForwardedRef<HTMLInputElement> },
  ) => ReactElement | null,
  {
    Size: InputSize,
  },
);
