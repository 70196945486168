import { css, type Theme } from '@emotion/react';

export const leftBar = (theme: Theme) => css`
  --left-menu-offset: 8px;

  width: calc(var(--left-menu-width) - var(--left-menu-offset));
  background-color: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.hard};

  overflow: auto;

  display: flex;
  flex-direction: column;

  position: fixed;
  top: var(--left-menu-offset);
  bottom: var(--left-menu-offset);
  left: var(--left-menu-offset);
  z-index: 1;
`;

export const header = (theme: Theme) => css`
  padding: calc(26px - var(--left-menu-offset, 0px)) 6px 12px 16px;
  flex: none;

  position: sticky;
  top: 0;
  background-color: ${theme.ds.colors.gray[0]};
`;

export const menu = css`
  padding: 12px;

  flex-grow: 1;
`;

export const footer = (theme: Theme) => css`
  position: sticky;
  bottom: 0;

  flex: none;

  background-color: ${theme.ds.colors.gray[0]};
`;
