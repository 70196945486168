import { useFormikContext } from 'formik';
import { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikInput, FormikSelect, FormLayout } from '@amalia/design-system/components';
import { intlConfig } from '@amalia/kernel/intl/types';
import { log } from '@amalia/kernel/logger/client';
import { UserExternalIdSource, UserHrisIdSource } from '@amalia/tenants/users/types';

import { useCompanyCurrencyOptions, useRoleOptions } from '../../../table';
import { useExternalIdSourceOptions } from '../../../table/cells/integrations/popover/external-id/select/useExternalIdSourceOptions';
import { useHrisIdSourceOptions } from '../../../table/cells/integrations/popover/hris-id/select/useHrisIdSourceOptions';
import { type CreateUserFormValues } from '../CreateUserForm';

import { SelectedRoleOption } from './role/selected-role-option/SelectedRoleOption';

const languageOptions = intlConfig.locales.map((locale) => ({
  value: locale,
  label: intlConfig.localeLabels[locale],
}));

export const CreateUserFormFields = memo(function CreateUserFormFields() {
  const { formatMessage } = useIntl();
  const roleOptions = useRoleOptions();
  const currencyOptions = useCompanyCurrencyOptions();
  const externalIdSourceOptions = useExternalIdSourceOptions({ withoutValues: [UserExternalIdSource.NONE] });
  const hrisIdSourceOptions = useHrisIdSourceOptions({ withoutValues: [UserHrisIdSource.NONE] });

  const { values, setFieldValue } = useFormikContext<CreateUserFormValues>();

  useEffect(() => {
    if (values.externalIdSource === null && values.externalId) {
      setFieldValue('externalId', '', true).catch(log.error);
    }
  }, [values.externalIdSource, values.externalId, setFieldValue]);

  useEffect(() => {
    if (values.hrisIdSource === null && values.hrisId) {
      setFieldValue('hrisId', '', true).catch(log.error);
    }
  }, [values.hrisIdSource, values.hrisId, setFieldValue]);

  return (
    <FormLayout size={FormLayout.Size.SMALL}>
      <FormLayout.Group>
        <FormLayout.Row>
          <FormikInput
            required
            label={<FormattedMessage defaultMessage="Email" />}
            name="email"
            placeholder={formatMessage({ defaultMessage: 'Enter email address' })}
          />

          <FormikSelect
            required
            isClearable={false}
            label={<FormattedMessage defaultMessage="Company role" />}
            name="role"
            options={roleOptions}
            SingleValueLabelComponent={SelectedRoleOption}
          />
        </FormLayout.Row>

        <FormLayout.Row>
          <FormikInput
            required
            label={<FormattedMessage defaultMessage="First name" />}
            name="firstName"
            placeholder={formatMessage({ defaultMessage: 'Enter first name' })}
          />

          <FormikInput
            required
            label={<FormattedMessage defaultMessage="Last name" />}
            name="lastName"
            placeholder={formatMessage({ defaultMessage: 'Enter last name' })}
          />
        </FormLayout.Row>

        <FormLayout.Row>
          <FormikSelect
            required
            isClearable={false}
            label={<FormattedMessage defaultMessage="Currency" />}
            name="currency"
            options={currencyOptions}
          />

          <FormikSelect
            required
            isClearable={false}
            label={<FormattedMessage defaultMessage="Language" />}
            name="language"
            options={languageOptions}
          />
        </FormLayout.Row>
      </FormLayout.Group>

      <FormLayout.Group>
        <FormLayout.Row>
          <FormikSelect
            required
            label={<FormattedMessage defaultMessage="External source" />}
            name="externalIdSource"
            options={externalIdSourceOptions}
          />

          <FormikInput
            disabled={values.externalIdSource === null}
            label={<FormattedMessage defaultMessage="External ID" />}
            name="externalId"
            placeholder={formatMessage({ defaultMessage: 'Enter external ID' })}
            required={values.externalIdSource !== null}
          />
        </FormLayout.Row>

        <FormLayout.Row>
          <FormikSelect
            required
            label={<FormattedMessage defaultMessage="HRIS source" />}
            name="hrisIdSource"
            options={hrisIdSourceOptions}
          />

          <FormikInput
            disabled={values.hrisIdSource === null}
            label={<FormattedMessage defaultMessage="HRIS ID" />}
            name="hrisId"
            placeholder={formatMessage({ defaultMessage: 'Enter HRIS ID' })}
            required={values.hrisIdSource !== null}
          />
        </FormLayout.Row>
      </FormLayout.Group>
    </FormLayout>
  );
});
