import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { type Company } from '@amalia/tenants/companies/types';

import { type Filter } from '../filters';

export enum ChallengeLaunchNotificationStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  SENDING = 'SENDING',
}

export interface RuleConfiguration {
  hideTotal?: boolean;
  mainKpiVariableOverwrite?: Variable['id'] | null;
  forecastPaymentDateFieldId?: Variable['id'] | null;
  paymentDateFieldId?: Variable['id'] | null;
  slackChannelId?: string | null;
  showHideRuleVariableId?: Variable['id'] | null;
  payMonthly?: boolean | null;
  paymentMonthOffset?: number | null;

  // Challenge.
  challengeComparisonVariableId?: Variable['id'] | null;
  challengeConditionVariableId?: Variable['id'] | null;
  /**
   * * `undefined`: not initialized.
   * * `null`: user specifically chose that the challenge has no prizes.
   * * value: variable id.
   */
  challengePricesTableVariableId?: Variable['id'] | null;
  challengeHideResults?: boolean | null;
  challengeStartDate?: number | null;
  challengeEndDate?: number | null;
  challengeLaunchNotificationStatus?: ChallengeLaunchNotificationStatus;
}

export enum RuleType {
  PAYOUT = 'PAYOUT',
  NON_PAYOUT = 'NON_PAYOUT',
  SPLIT = 'SPLIT',
  HOLD_AND_RELEASE = 'HOLD_AND_RELEASE',
  LINE_PAYOUT = 'LINE_PAYOUT',
  CHALLENGE = 'CHALLENGE',
}

// List of rule types that generate payments during compute.
export const RULE_TYPES_PAYOUT = [
  RuleType.PAYOUT,
  RuleType.SPLIT,
  RuleType.HOLD_AND_RELEASE,
  RuleType.LINE_PAYOUT,
] as const;

export const isRulePayout = (ruleDefinition?: Pick<Rule, 'type'>): boolean =>
  !!ruleDefinition && RULE_TYPES_PAYOUT.includes(ruleDefinition.type);

export const RULE_TYPES_LABELS: Record<RuleType, string> = {
  [RuleType.PAYOUT]: 'Payout',
  [RuleType.LINE_PAYOUT]: 'Payout',
  [RuleType.NON_PAYOUT]: 'Non-Payout',
  [RuleType.SPLIT]: 'Split Payout',
  [RuleType.HOLD_AND_RELEASE]: 'Hold & Release',
  [RuleType.CHALLENGE]: 'Challenge',
};

export enum RuleTypeWithAdjustment {
  ADJUSTMENT = 'ADJUSTMENT',
}

export interface HoldAndReleaseSettings {
  releaseCondition?: string;
  paymentDate?: string;
  forecastPaymentDate?: string;
}

export interface Rule {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;

  name: string;
  machineName: string;
  description: string | null;
  formula: string;
  type: RuleType;

  filter?: Filter;
  filterId: string | null;

  planId: string | null;

  // Split
  splitVariables: string[] | null;

  // H&R
  commissionVariableId: string | null;
  releaseConditionVariableId: string | null;

  // Configuration
  configuration: RuleConfiguration | null;

  filterMachineName?: string;
}

export enum RuleMainType {
  RECORDS = 'RECORDS',
  TOTAL = 'TOTAL',
  CHALLENGE = 'CHALLENGE',
}
