import { type ReactNode, memo, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useBoolState, useStateWithRef } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { DatePickerBase, type DatePickerBaseProps } from '../../../data-input/date-picker-base/DatePickerBase';
import { FilterDatePickerSymbol } from '../getFilterType';

import { FilterDatePickerTag, type FilterDatePickerTagProps } from './filter-date-picker-tag/FilterDatePickerTag';

export type FilterDatePickerProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    Omit<
      DatePickerBaseProps<TWithRange>,
      'children' | 'clearButtonLabel' | 'isClearable' | 'isOpen' | 'onChangeIsOpen' | 'placement'
    >,
    Pick<FilterDatePickerTagProps, 'emptyValueLabel' | 'error' | 'id' | 'isStatic' | 'label'>,
    {
      /** Override label of filter inside the "Add filter" menu. Defaults to `label` (with a 0 count if function). */
      menuLabel?: ReactNode;
    },
  ]
>;

const FilterDatePickerBase = function FilterDatePicker<TWithRange extends boolean | undefined = undefined>({
  value: propsValue,
  onChange: propsOnChange,
  error,
  label,
  emptyValueLabel,
  isStatic,
  ...props
}: FilterDatePickerProps<TWithRange>) {
  const [internalValue, setInternalValue, internalValueRef] = useStateWithRef(propsValue);

  // Sync internalValue with propsValue.
  useEffect(() => {
    setInternalValue(propsValue);
  }, [propsValue, setInternalValue]);

  const { isOpen, setOpenTrue, setOpenFalse } = useBoolState(false, 'open');

  const handleChangeIsOpen = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        setOpenTrue();
      } else {
        propsOnChange?.(internalValueRef.current);
        setOpenFalse();
      }
    },
    [internalValueRef, propsOnChange, setOpenFalse, setOpenTrue],
  );

  return (
    <DatePickerBase<TWithRange>
      {...props}
      isClearable
      clearButtonLabel={<FormattedMessage defaultMessage="Clear filter" />}
      placement="bottom-start"
      value={internalValue}
      onChange={setInternalValue}
      onChangeIsOpen={handleChangeIsOpen}
    >
      {/* Extra div because Popover uses onClick with an event and the Tag passes the value and it crashes. */}
      <div>
        <FilterDatePickerTag<TWithRange>
          disabled={props.disabled}
          emptyValueLabel={emptyValueLabel}
          error={error}
          id={props.id}
          isCalendarOpen={isOpen}
          isStatic={isStatic}
          label={label}
          selectsRange={props.selectsRange}
          value={internalValue}
        />
      </div>
    </DatePickerBase>
  );
};

export const FilterDatePicker = Object.assign(memo(FilterDatePickerBase) as typeof FilterDatePickerBase, {
  symbol: FilterDatePickerSymbol,
});
