import { css } from '@emotion/react';
import { type ReactNode, forwardRef, type ForwardedRef } from 'react';

import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';

export type YearSelectorButtonProps = {
  readonly value?: ReactNode;
  readonly isOpen?: boolean;
};

export const YearSelectorButton = forwardRef(function YearSelectorButton(
  { value, isOpen = false, ...props }: YearSelectorButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <UnstyledButton
      {...props}
      ref={ref}
      data-testid="year-selector-trigger"
      css={(theme) => css`
        border-radius: ${theme.ds.borderRadiuses.round};

        padding: 3.5px 8px;
        transition: ${theme.ds.transitions.default('background-color', 'box-shadow', 'color')};

        line-height: 1;

        cursor: pointer;

        color: ${isOpen ? theme.ds.colors.primary[500] : theme.ds.colors.gray[900]};
        background-color: ${isOpen ? theme.ds.colors.primary[50] : 'transparent'};

        &:hover {
          background-color: ${theme.ds.colors.primary[50]};
        }

        &:focus-visible {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
          outline: 1px solid ${theme.ds.colors.primary[200]};
          outline-offset: -1px;
        }
      `}
    >
      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{value}</Typography>
    </UnstyledButton>
  );
});
