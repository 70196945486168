import { css } from '@emotion/react';
import { isNil } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isComputeEnginePrimitiveType } from '@amalia/payout-calculation/types';

import '@amalia/design-system/components';

import { useFormatValue } from '../../hooks/use-format-value';

interface FormattedPrettyValueProps {
  readonly value: unknown;
  readonly format: FormatsEnum;
  readonly currencySymbol?: CurrencySymbolsEnum;
}

export const FormattedPrettyValue = memo(function FormattedPrettyValue({
  value,
  format,
  currencySymbol,
}: FormattedPrettyValueProps) {
  const formatValue = useFormatValue();

  if (!isComputeEnginePrimitiveType(value)) {
    return '-';
  }

  if (isNil(value)) {
    return (
      <span
        css={(theme) => css`
          color: ${theme.ds.colors.gray['500']};
        `}
      >
        <FormattedMessage defaultMessage="No value" />
      </span>
    );
  }

  if (value === '') {
    return (
      <em>
        <FormattedMessage defaultMessage="empty string" />
      </em>
    );
  }

  return formatValue(value, format, { currencySymbol });
});
