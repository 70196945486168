import { css, type Theme } from '@emotion/react';
import 'cropperjs/dist/cropper.css';

export const avatarEditorStyle = (theme: Theme) => css`
  .cropper-container,
  .cropper-bg {
    border-radius: ${theme.ds.borderRadiuses.squared};
  }

  .cropper-view-box,
  .cropper-face {
    border-radius: 100%;
  }

  .cropper-view-box {
    outline: 0;
    box-shadow: 0 0 0 1px ${theme.ds.colors.gray[100]};
  }

  .cropper-point {
    background-color: ${theme.ds.colors.gray[100]};
  }

  .cropper-line {
    opacity: 0.2;
    background-color: ${theme.ds.colors.gray[100]};
  }

  .cropper-line.line-n,
  .cropper-line.line-s {
    height: 1px;
  }

  .cropper-line.line-w,
  .cropper-line.line-e {
    width: 1px;
  }

  .cropper-line.line-n {
    top: 0;
  }

  .cropper-line.line-s {
    bottom: 0;
  }

  .cropper-line.line-w {
    left: 0;
  }

  .cropper-line.line-e {
    right: 0;
  }
`;
