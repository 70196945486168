import { css } from '@emotion/react';
import { IconFile, IconTrash } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

import { eventStopPropagation } from '@amalia/ext/web';

import { IconButton } from '../../../general/icon-button/IconButton';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';
import { Group } from '../../../layout/group/Group';
import { Paper } from '../../../layout/paper/Paper';
import { Stack } from '../../../layout/stack/Stack';

export type DroppedFileProps = {
  readonly file: File;
  readonly onRemove?: (file: File) => void;
};

export const DroppedFile = memo(function DroppedFile({ file, onRemove }: DroppedFileProps) {
  const { formatMessage } = useIntl();
  const handleRemove = useCallback(() => onRemove?.(file), [onRemove, file]);

  return (
    <Paper onClick={eventStopPropagation}>
      <Group
        align="center"
        gap={12}
        css={css`
          padding: 6px 16px;
        `}
      >
        <IconFile
          size={24}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[500]};
          `}
        />

        <Stack grow={1}>
          <Typography
            as={TextOverflow}
            variant={Typography.Variant.BODY_SMALL_MEDIUM}
          >
            {file.name}
          </Typography>

          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            <FormattedNumber
              maximumFractionDigits={1}
              notation="compact"
              style="unit"
              unit="byte"
              unitDisplay="narrow"
              value={file.size}
            />
          </Typography>
        </Stack>

        <IconButton
          icon={<IconTrash />}
          label={formatMessage({ defaultMessage: 'Remove file' })}
          size={IconButton.Size.SMALL}
          variant={IconButton.Variant.DANGER}
          onClick={handleRemove}
        />
      </Group>
    </Paper>
  );
});
