import { parse, stringify } from 'qs';
import { useCallback, useMemo, useRef } from 'react';
import { type Location, useLocation } from 'react-router-dom';

import { useNavigate } from '../use-navigate/use-navigate';

export type UrlState = string | null | undefined;

type UseUrlStateOptions<TState extends UrlState> = {
  navigateMode?: 'push' | 'replace';
  /** If the value in the url does not match the schema, the default value is used. */
  validationSchema?: { validateSync: (value: unknown, options?: { strict?: boolean }) => TState | undefined };
};

export type UseUrlStateValue<TState extends UrlState> = [
  state: TState,
  setState: (
    newValue: TState | ((prev: TState) => TState),
    options?: Partial<Pick<UseUrlStateOptions<TState>, 'navigateMode'>>,
  ) => void,
];

export const useUrlState = <TState extends UrlState>(
  key: string,
  defaultValue: TState | (() => TState),
  { navigateMode = 'push', validationSchema }: UseUrlStateOptions<TState> = {},
): UseUrlStateValue<TState> => {
  const { search, hash, state: locationState } = useLocation() as Location<unknown>;
  const navigate = useNavigate();

  const initialStateRef = useRef(typeof defaultValue === 'function' ? defaultValue() : defaultValue);

  const parsedQs = useMemo(() => parse(search, { ignoreQueryPrefix: true }), [search]);

  const urlState = useMemo(
    () => ({
      [key]: initialStateRef.current,
      ...parsedQs,
    }),
    [key, parsedQs],
  );

  const setState: UseUrlStateValue<TState>[1] = useCallback(
    (newValue, { navigateMode: effectiveNavigateMode = navigateMode } = {}) => {
      const newUrlState = {
        ...urlState,
        [key]: (typeof newValue === 'function' ? newValue(urlState[key] as TState) : newValue) ?? undefined,
      };

      navigate(
        {
          hash,
          search: stringify(newUrlState),
        },
        {
          replace: effectiveNavigateMode === 'replace',
          state: locationState,
        },
      );
    },
    [navigate, navigateMode, hash, locationState, key, urlState],
  );

  const value = useMemo(() => {
    const urlValue = urlState[key] as TState;
    try {
      return validationSchema
        ? (validationSchema.validateSync(urlValue, { strict: true }) ?? initialStateRef.current)
        : urlValue;
    } catch (err) {
      return initialStateRef.current;
    }
  }, [urlState, key, validationSchema]);

  return [value, setState];
};
