import { lazy } from 'react';

export { ReactComponent as IconAmaliaLogo } from './amalia-logo.svg';
export { ReactComponent as IconAmalia } from './amalia.svg';

export const IconAmaliaChristmas = lazy(async () => ({
  default: (await import('./amalia-christmas.svg')).ReactComponent,
}));

export const IconAmaliaEaster = lazy(async () => ({
  default: (await import('./amalia-easter.svg')).ReactComponent,
}));

export const IconAmaliaAprilFools = lazy(async () => ({
  default: (await import('./amalia-april-fools.svg')).ReactComponent,
}));

export const IconAmaliaHalloween = lazy(async () => ({
  default: (await import('./amalia-halloween.svg')).ReactComponent,
}));

export const IconAmaliaPinktober = lazy(async () => ({
  default: (await import('./amalia-pinktober.svg')).ReactComponent,
}));

export * from './data';
