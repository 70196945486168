import { css } from '@emotion/react';
import { isNil } from 'lodash';
import { type ForwardedRef, forwardRef, memo, type ReactNode } from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { TextOverflow, type TextOverflowProps } from '../../../../general/text-overflow/TextOverflow';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';

import * as styles from './CellWithActions.styles';

export type CellWithActionsProps = {
  readonly tooltipContent?: TextOverflowProps['tooltipContent'];
  readonly tooltipPlacement?: TextOverflowProps['tooltipPlacement'];

  /** Cell actions. */
  readonly actions: ReactNode;
  /** Cell content. */
  readonly children?: ReactNode;
  /** Hide actions when cell is not hovered. */
  readonly hideActions?: boolean;
  /** Typography variant. */
  readonly typographyVariant?: TypographyVariant;
  /** root element lassName */
  readonly className?: string;
};

const CellWithActionsBase = forwardRef(function CellWithActions(
  {
    actions,
    children,
    tooltipContent,
    tooltipPlacement,
    hideActions = true,
    typographyVariant = TypographyVariant.BODY_BASE_REGULAR,
    className,
  }: CellWithActionsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <TableDataCellContent
      ref={ref}
      className={className}
      css={styles.cellWithActions}
    >
      {!isNil(children) && (
        <TextOverflow
          tooltipContent={tooltipContent}
          tooltipPlacement={tooltipPlacement}
          css={(theme) => css`
            ${theme.ds.typographies[typographyVariant]};
          `}
        >
          {children}
        </TextOverflow>
      )}

      {!!actions && (
        <div css={[styles.actionsContainer, hideActions && styles.actionsContainerHideHover]}>{actions}</div>
      )}
    </TableDataCellContent>
  );
});

export const CellWithActions = memo(CellWithActionsBase);
