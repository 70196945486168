import { type Theme, css } from '@emotion/react';

export const IS_ACTIVE_CLASSNAME = 'is-active';

export const datePickerBaseHeader = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 16px;

  color: ${theme.ds.colors.gray[900]};
`;

export const datePickerHeaderButton = (theme: Theme) => css`
  border-radius: ${theme.ds.borderRadiuses.round};
  padding: 3.5px 8px;

  transition: ${theme.ds.transitions.default('background-color', 'box-shadow', 'color')};

  color: ${theme.ds.colors.gray[900]};
  background-color: transparent;

  &:hover,
  &.${IS_ACTIVE_CLASSNAME} {
    background-color: ${theme.ds.colors.primary[50]};
  }

  &.${IS_ACTIVE_CLASSNAME} {
    color: ${theme.ds.colors.primary[500]};
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
  }
`;

export const headerSeparator = (theme: Theme) => css`
  height: 15.5px;
  transform: rotate(17.25deg);
  border-left: 1px solid ${theme.ds.colors.gray[200]};
`;
