import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton, type IconButtonProps } from '../../../../general/icon-button/IconButton';

export type CellIconActionProps = MergeAll<
  [
    Omit<IconButtonProps, 'size'>,
    {
      /** If the <Table.Cell.WithActions /> uses hideActions={false}, set this to true to only this specific action on hover. */
      showOnHoverCell?: boolean;
    },
  ]
>;

const CellIconActionForwardRef = forwardRef(function CellIconAction(
  { showOnHoverCell, withBackground, ...props }: CellIconActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <IconButton
      {...props}
      ref={ref}
      size={IconButton.Size.SMALL}
      withBackground={withBackground !== false} // With background unless specified otherwise.
      css={
        !!showOnHoverCell &&
        css`
          td:not(:hover) & {
            display: none;
          }
        `
      }
    />
  );
});

export const CellIconAction = Object.assign(memo(CellIconActionForwardRef), {
  Variant: IconButton.Variant,
});
