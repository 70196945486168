import clsx from 'clsx';
import dayjs from 'dayjs';
import { times } from 'lodash';
import { memo, useMemo } from 'react';

import { UnstyledButton } from '../../../../general/unstyled-button/UnstyledButton';
import { SelectDropdown } from '../../../../overlays/select-dropdown/SelectDropdown';
import { type SelectDropdownOption } from '../../../../overlays/select-dropdown/SelectDropdown.types';
import * as styles from '../DatePickerBaseHeader.styles';

export type DatePickerBaseHeaderMonthPickerProps = {
  readonly value: Date;
  readonly onChange: (month: number) => void;
};

export const DatePickerBaseHeaderMonthPicker = memo(function DatePickerBaseHeaderMonthPicker({
  value,
  onChange,
}: DatePickerBaseHeaderMonthPickerProps) {
  // Memo for localization with dayjs.
  const monthsOptions = useMemo(
    () =>
      times(12).map((i) => ({
        label: dayjs().month(i).format('MMMM'),
        value: i,
      })),
    [],
  );

  return (
    <SelectDropdown<SelectDropdownOption<number>>
      hideSearchInput
      options={monthsOptions}
      value={value.getMonth()}
      onChange={onChange}
    >
      {({ isDropdownOpen, value }) => (
        <UnstyledButton
          className={clsx({ [styles.IS_ACTIVE_CLASSNAME]: isDropdownOpen })}
          css={styles.datePickerHeaderButton}
          data-testid="date-picker-month-selector"
        >
          {value!.label}
        </UnstyledButton>
      )}
    </SelectDropdown>
  );
});
