import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert, type OmitHead } from '@amalia/ext/typescript';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import { printNullOrInfinity } from '../../helpers/print-null-or-infinity/print-null-or-infinity';
import { formatAmount } from '../amount/format-amount';
import { formatDate } from '../date/format-date';
import { formatNumber } from '../number/format-number';
import { formatPercent } from '../percent/format-percent';
import { type FormatOptions } from '../types';

export const formatValue = (
  locale: string,
  value: ComputeEnginePrimitiveTypes | undefined,
  format: FormatsEnum,
  {
    currencySymbol,
    currencyRate,
    intlOptions,
    formatOptions,
  }: {
    currencySymbol?: CurrencySymbolsEnum;
    currencyRate?: number;
    intlOptions?: Intl.NumberFormatOptions;
    formatOptions?: FormatOptions;
  } = {},
) => {
  switch (format) {
    case FormatsEnum.table:
      return printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () => '');

    case FormatsEnum.date:
    case FormatsEnum['date-time']:
      return formatDate(locale, value as number | string | null, { formatOptions });

    case FormatsEnum.percent:
      return formatPercent(locale, value as number | null | undefined, { intlOptions, formatOptions });

    case FormatsEnum.number:
      return formatNumber(locale, value as number | null | undefined, { intlOptions, formatOptions });

    case FormatsEnum.boolean:
      return printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () => `${(value as boolean | null) ?? ''}`);

    case FormatsEnum.currency: {
      if (isCurrencyValue(value)) {
        return formatAmount(locale, value.value, value.symbol, { currencyRate, intlOptions, formatOptions });
      }

      assert(currencySymbol, `Currency needed for formatting value: ${value}.`);
      return formatAmount(locale, value as number | null | undefined, currencySymbol, {
        currencyRate,
        intlOptions,
        formatOptions,
      });
    }

    case FormatsEnum.text:
    default:
      // I don't think we can get a CurrencyValue here but ESLint complained about [object Object].
      return printNullOrInfinity(
        value,
        formatOptions?.printNullOrInfinity,
        () => `${(isCurrencyValue(value) ? value.value : value) ?? ''}`,
      );
  }
};

export type FormatValue = (...args: OmitHead<Parameters<typeof formatValue>>) => ReturnType<typeof formatValue>;
