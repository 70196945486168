import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Plan, type Rule, type RuleTemplateSingleRateCommission } from '@amalia/payout-definition/plans/types';

import { calculationScopesQueryKeys } from '../calculation-scope/calculation-scope.keys';
import { plansForecastQueryKeys } from '../plan-forecast/plan-forecast.keys';
import { plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';
import { variablesQueryKeys } from '../variables/variables.keys';

import { RuleTemplatesApiClient } from './rule-templates.api-client';
import { ruleTemplateMutationKeys } from './rule-templates.keys';

export const useApplySingleRateCommissionTemplateToRule = (planId: Plan['id'], ruleId: Rule['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: ruleTemplateMutationKeys.applySingleRateCommissionTemplate(ruleId),
    mutationFn: (ruleTemplateSingleRateCommission: RuleTemplateSingleRateCommission) =>
      RuleTemplatesApiClient.applySingleRateCommissionTemplateToRule(planId, ruleId, ruleTemplateSingleRateCommission),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Single rate commission template applied successfully.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: calculationScopesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId) }),
        queryClient.invalidateQueries({
          queryKey: plansQueryKeys.template.all(),
        }),
        queryClient.invalidateQueries({ queryKey: plansForecastQueryKeys.details(planId) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while applying single rate commission template: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
