import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { memo } from 'react';
import { type DatePickerProps as ReactDatePickerProps, type ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton } from '../../../general/icon-button/IconButton';
import { Typography } from '../../../general/typography/Typography';
import { Group } from '../../../layout/group/Group';

import * as styles from './DatePickerBaseHeader.styles';
import { DatePickerBaseHeaderMonthPicker } from './month-picker/DatePickerBaseHeaderMonthPicker';
import { DatePickerBaseHeaderYearPicker } from './year-picker/DatePickerBaseHeaderYearPicker';

const YEARS_IN_PERIOD = 12;

export type DatePickerBaseHeaderProps = MergeAll<
  [
    ReactDatePickerCustomHeaderProps,
    Pick<ReactDatePickerProps, 'showMonthYearPicker' | 'showQuarterYearPicker' | 'showYearPicker'>,
  ]
>;

export const DatePickerBaseHeader = memo(function DatePickerBaseHeader({
  changeMonth,
  changeYear,
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  prevMonthButtonDisabled,
  prevYearButtonDisabled,
  nextMonthButtonDisabled,
  nextYearButtonDisabled,
  date,
  showMonthYearPicker,
  showQuarterYearPicker,
  showYearPicker,
}: DatePickerBaseHeaderProps) {
  // There are only two states for the header: year or month.
  const isYearPicker = showMonthYearPicker || showQuarterYearPicker || showYearPicker;

  const selectedYear = date.getFullYear();
  const endPeriod = Math.ceil(selectedYear / YEARS_IN_PERIOD) * YEARS_IN_PERIOD;
  const startPeriod = endPeriod - (YEARS_IN_PERIOD - 1);

  const { formatMessage } = useIntl();

  return (
    <div css={styles.datePickerBaseHeader}>
      <IconButton
        disabled={isYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled}
        icon={<IconChevronLeft />}
        label={
          isYearPicker
            ? showYearPicker
              ? formatMessage({ defaultMessage: 'Previous year period' })
              : formatMessage({ defaultMessage: 'Previous year' })
            : formatMessage({ defaultMessage: 'Previous month' })
        }
        onClick={isYearPicker ? decreaseYear : decreaseMonth}
      />

      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
        {isYearPicker ? (
          showYearPicker ? (
            <FormattedMessage
              defaultMessage="{startPeriod} - {endPeriod}"
              values={{ startPeriod, endPeriod }}
            />
          ) : (
            <DatePickerBaseHeaderYearPicker
              value={date}
              onChange={changeYear}
            />
          )
        ) : (
          <Group
            align="center"
            gap={6}
          >
            <DatePickerBaseHeaderMonthPicker
              value={date}
              onChange={changeMonth}
            />

            <div css={styles.headerSeparator} />

            <DatePickerBaseHeaderYearPicker
              value={date}
              onChange={changeYear}
            />
          </Group>
        )}
      </Typography>

      <IconButton
        disabled={isYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled}
        icon={<IconChevronRight />}
        label={
          isYearPicker
            ? showYearPicker
              ? formatMessage({ defaultMessage: 'Next year period' })
              : formatMessage({ defaultMessage: 'Next year' })
            : formatMessage({ defaultMessage: 'Next month' })
        }
        onClick={isYearPicker ? increaseYear : increaseMonth}
      />
    </div>
  );
});
