import { without } from 'lodash';
import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';

import { type FileDropzoneProps } from '../FileDropzone';

export const useFileDropzoneState = (): Pick<FileDropzoneProps, 'files' | 'onDropAccepted' | 'onRemoveFile'> & {
  onChangeFiles: Dispatch<SetStateAction<File[]>>;
} => {
  const [files, setFiles] = useState<File[]>([]);

  const handleRemoveFile = useCallback((file: File) => setFiles((currentFiles) => without(currentFiles, file)), []);

  return {
    files,
    onChangeFiles: setFiles,
    onDropAccepted: setFiles,
    onRemoveFile: handleRemoveFile,
  };
};
