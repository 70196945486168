import { useQuery } from '@tanstack/react-query';

import { type DataExportParams } from '@amalia/reporting/exports/shared';

import { DataExportsApiClient } from '../api-client/dataExports.api-client';

export const exportsQueryKeys = {
  all: () => ['exports'] as const,
  statementsInError: (dataExportParams: DataExportParams) =>
    [...exportsQueryKeys.all(), 'statements-in-error', dataExportParams] as const,
};

export const useStatementsInError = (dataExportParams: DataExportParams) =>
  useQuery({
    enabled: !!dataExportParams.periodIds?.length,
    queryKey: exportsQueryKeys.statementsInError(dataExportParams),
    queryFn: async () => DataExportsApiClient.getStatementsInError(dataExportParams),
  });
