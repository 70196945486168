interface CompanyPreferencesSalesforce {
  oAuthFlow?: 'authorization' | 'client_credentials';
  useSandbox?: boolean;
}

export interface CompanyPreferences {
  connectors?: Record<string, CompanyPreferencesSalesforce | undefined>;
}

export enum CompanyCustomizationForecastVsActualColors {
  GREEN = 'GREEN',
  CYAN = 'CYAN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  MAGENTA = 'MAGENTA',
  PURPLE = 'PURPLE',
  GRAY = 'GRAY',
}

export interface CompanyCustomization {
  holdAndReleasePaymentDateLabel?: string;
  holdAndReleaseForecastPaymentDateLabel?: string;
  totalCommissionLabel?: string;
  reportsPrecision?: number;
  forecastShowComparison?: boolean;
  forecastBelowActualColor?: CompanyCustomizationForecastVsActualColors;
  forecastAboveActualColor?: CompanyCustomizationForecastVsActualColors;
}

export const CompanyCustomizationDefault = {
  reportPrecision: 2,
  forecastShowComparison: true,
  forecastBelowActualColor: CompanyCustomizationForecastVsActualColors.GREEN,
  forecastAboveActualColor: CompanyCustomizationForecastVsActualColors.RED,
  legacyKpiCardView: false,
};
