import { type Theme, css } from '@emotion/react';

export const pageHeaderContainerSticky = (theme: Theme) => css`
  --header-offset: 8px;
  --header-padding-x: 24px;

  position: fixed;
  top: var(--header-offset);
  left: var(--left-menu-width, 0px);
  right: var(--right-drawer-width, 0px);
  width: calc(100% - var(--left-menu-width, 0px) - var(--right-drawer-width, 0px) - var(--header-padding-x) * 2);
  margin: 0 auto;
  max-width: calc(var(--page-container-max-width, 100%) - var(--page-container-padding-x, 0px) * 2);

  border-radius: ${theme.ds.borderRadiuses.squared};

  z-index: 1299;
  background-color: ${theme.ds.colors.gray[0]};
  padding: 8px var(--header-padding-x);

  box-shadow: ${theme.ds.shadows.hard};

  transition: ${theme.ds.transitions.default('right', 'left')};

  animation: smooth-appear ${theme.ds.transitions.default.durationMs}ms ${theme.ds.transitions.default.easing};
  @keyframes smooth-appear {
    from {
      top: -100px;
    }
    to {
      top: var(--header-offset);
    }
  }
`;

export const alertContainer = css`
  margin-top: 12px;

  &:empty {
    display: none;
  }
`;
