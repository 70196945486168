import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { assert, toError } from '@amalia/ext/typescript';
import { useUploadAvatar } from '@amalia/tenants/users/profile/state';

/**
 * Hook to handle AvatarEditor component logic.
 */
export const useAvatarEditor = () => {
  const { formatMessage } = useIntl();

  // We need to await its completion before closing the modal, so we use mutateAsync instead of mutate
  const { mutateAsync: uploadAvatarBase64, error: uploadAvatarBase64Error, isPending } = useUploadAvatar();

  const [avatarBase64, setAvatarBase64] = useState<string | null>(null);

  /**
   * Error returned by the API or by the business logic (image too large, file not supported).
   *
   * If there is no error, it returns null.
   */
  const error = useMemo(
    () => (!!uploadAvatarBase64Error && toError(uploadAvatarBase64Error).message) || null,
    [uploadAvatarBase64Error],
  );

  const submitAvatar = useCallback(async () => {
    assert(
      avatarBase64,
      formatMessage({
        defaultMessage: 'An avatar must be provided',
      }),
    );
    return uploadAvatarBase64(avatarBase64);
  }, [avatarBase64, formatMessage, uploadAvatarBase64]);

  const updateAvatarPreview = useCallback((imageDataBase64: string) => {
    setAvatarBase64(imageDataBase64);
  }, []);

  return useShallowObjectMemo({
    avatarBase64,
    error,
    isPending,
    submitAvatar,
    updateAvatarPreview,
  });
};
