import { Fragment, type ReactNode } from 'react';

import { useBoolState } from '@amalia/ext/react/hooks';

export type BoolStateProps<TName extends string = 'state'> = {
  readonly initialState?: boolean | (() => boolean);
  readonly name?: TName;
  readonly children: (boolState: ReturnType<typeof useBoolState<TName>>) => ReactNode;
};

export const BoolState = function BoolState<TName extends string = 'state'>({
  initialState = false,
  name,
  children,
}: BoolStateProps<TName>) {
  return <Fragment>{children(useBoolState<TName>(initialState, name))}</Fragment>;
};
