import { type VariableType } from '@amalia/amalia-lang/tokens/types';
import { type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';

import { type ComputedFunctionArgs } from './computed-functions';
import { type ComputedOverwrite } from './computed-overwrites';

export enum ComputedItemTypes {
  VARIABLE = 'VARIABLE',
  FUNCTION_RESULT = 'FUNCTION_RESULT',
  RULE = 'RULE',
}

export type ComputeEnginePrimitiveTypes = RecordContentPropertyType | undefined;

export const isComputeEnginePrimitiveType = (value: unknown): value is ComputeEnginePrimitiveTypes =>
  typeof value === 'string' ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  value === null ||
  isCurrencyValue(value) ||
  value === undefined;

// In the custom object table, we only have records of primitive types. However, in the computation engine,
// the row can be augmented with 1-1 relations, 1-N relations... and the type should be recursive.
export interface DatasetRowContent {
  [key: string]: ComputeEngine2DTable | ComputeEnginePrimitiveTypes | DatasetRowContent | DatasetRowContent[];
}

export const isComputedEngine2DTable = <T extends ComputeEnginePrimitiveTypes>(
  value: unknown,
): value is ComputeEngine2DTable<T> => Array.isArray(value) && Array.isArray(value[0]);

export type ComputeEngine2DTable<T extends ComputeEnginePrimitiveTypes = ComputeEnginePrimitiveTypes> = T[][];

export type ComputeEngineResult =
  | ComputeEngine2DTable
  | ComputeEnginePrimitiveTypes
  | ComputeEnginePrimitiveTypes[]
  | DatasetRowContent
  | DatasetRowContent[];

export interface ComputedItemBase<TValue extends ComputeEngineResult = ComputeEngineResult> {
  /** A unique id to help us build dependency tree. */
  id: string;
  /** Ids of this item's parents. */
  parentIds: string[];
  /** Item type. */
  type: ComputedItemTypes;
  /** Computed value. Undefined means that it hasn't been computed yet. */
  value?: TValue;
  /** Computed items can have an overwrite. */
  overwrite?: ComputedOverwrite;

  // Currency can be different than user.
  currency?: CurrencySymbolsEnum;
  // Id of computedItems to get currency from.
  // If we found multiple, it means that we should check their homogeneity
  currencyFrom?: string[];

  // Time boundaries.
  startDate?: number;
  endDate?: number;

  evaluations?: ComputedFunctionResult[];

  variableMachineName?: string;
}

export interface ComputedFunctionResult extends ComputedItemBase {
  type: ComputedItemTypes.FUNCTION_RESULT;
  function: string;

  args?: ComputedFunctionArgs;
}

export interface ComputedVariable<TValue extends ComputeEngineResult = ComputeEngineResult>
  extends ComputedItemBase<TValue> {
  type: ComputedItemTypes.VARIABLE;
  variableType: VariableType;

  // Machine name that refers to the variable definition.
  variableMachineName: string;

  // For object variables, we have the definition of the object.
  // For instance for `opportunity.amount`, it's `opportunity`.
  customObjectMachineName?: string;

  // Follow those relations in the dataset to find this object.
  relationLevel?: string;
}

export interface ComputedRule extends ComputedItemBase<number | null> {
  type: ComputedItemTypes.RULE;
  ruleMachineName: string;
  datasetToDisplayIds?: string[] | null;
}

export type ComputedItem = ComputedFunctionResult | ComputedRule | ComputedVariable;
