import { isNil } from 'lodash';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type OmitHead } from '@amalia/ext/typescript';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import { formatValue } from '../value/format-value';

const FORMATS_TO_FORCE_TO_SHOW = [FormatsEnum.date, FormatsEnum.percent] as const;

export const formatDatasetCell = (
  locale: string,
  valueToFormat: ComputeEnginePrimitiveTypes,
  cellFormat: FormatsEnum = FormatsEnum.text,
  isOverwrite: boolean = false,
) =>
  // IDK this was legacy, not sure why we want to force show null only for those formats.
  (isOverwrite && FORMATS_TO_FORCE_TO_SHOW.includes(cellFormat)) || !isNil(valueToFormat)
    ? formatValue(locale, valueToFormat, cellFormat, {
        formatOptions: {
          printNullOrInfinity: true,
        },
      }) || '—'
    : '—';

export type FormatDatasetCell = (
  ...args: OmitHead<Parameters<typeof formatDatasetCell>>
) => ReturnType<typeof formatDatasetCell>;
